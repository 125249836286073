import React from 'react'
import styled from 'styled-components'
import { UIButton } from '..'
import { observer } from 'mobx-react';
import UILoadingButton from '../Loading/Button';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`

const Action = styled(UIButton)`
  width: 200px;

  &:first-child:not(:last-child) {
    margin-right: 5px;
  }

  &:last-child:not(:first-child) {
    margin-left: 5px;
  }
`

const UIModalActions = ({ onConfirm = { props: {} }, onCancel = { props: {} } }) =>
  <Wrapper>
    {
      onCancel && onCancel.show && (
        <Action
          {...onCancel.props}
          large
          outline
          onClick={onCancel.click}>
          {onCancel.message}
        </Action>
      )
    }
    {
      onConfirm && onConfirm.show && (
        <Action
          {...onConfirm.props}
          large
          primary
          onClick={onConfirm.click}>
          <UILoadingButton loading={onConfirm.loading}>
            {onConfirm.message}
          </UILoadingButton>
        </Action>
      )
    }
  </Wrapper>

export default observer(UIModalActions)
