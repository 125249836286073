import styled from 'styled-components'
import { device } from '../../../constants'
import { observer } from 'mobx-react';

const UIAppMain = styled.main`
  grid-area: main;

  ${({ authenticated }) => authenticated && `
    padding: 20px 20px 60px 20px;

    @media ${device.laptop} {
      padding: 50px 40px 50px 60px;
    }
  `}
`

export default observer(UIAppMain)
