import { db, firestore } from '../../firebase'
import { USERS, CONTACT } from '../Collections'

const contactRef = uid => db.collection(`${USERS}/${uid}/${CONTACT}`)

export const add = (uid, contact) => contactRef(uid).doc('current').set({
  ...contact,
  addedAt: firestore.FieldValue.serverTimestamp(),
})
export const get = uid => contactRef(uid).doc('current').get()
