import React from "react";
import styled from "styled-components";
import { UIIcon, UIFilter } from "..";
import * as UITable from "../Table";
import { observer } from "mobx-react";

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 4px;
`;

const BorderedColumn = styled(UITable.Column).attrs(() => ({
  bordered: true,
}))``;

const TxLink = styled.a`
  font-weight: normal;
  text-decoration: underline;
`;

const sortCoins = (orderBy) => (a, b) => {
  if (orderBy.allocation)
    return (
      (b.allocation || b.value.allocation) -
      (a.allocation || a.value.allocation)
    );
  if (orderBy.value) return b.value.current - a.value.current;
  if (orderBy.cost) return b.value.cost - a.value.cost;
};

const UITableDistribution = ({
  coins,
  value,
  amount,
  tx,
  cost,
  price,
  allocation,
  orderBy = { allocation: true },
}) => (
  <Table>
    <UITable.Header>
      <tr>
        <UITable.Column as="th">Moeda</UITable.Column>
        {allocation && (
          <UITable.Column as="th" hiddenXs>
            Proporção
          </UITable.Column>
        )}
        {amount && <UITable.Column as="th">Quantidade</UITable.Column>}
        {tx && <UITable.Column as="th">TX</UITable.Column>}
        {price && <UITable.Column as="th">Preço</UITable.Column>}
        {cost && <UITable.Column as="th">Total</UITable.Column>}
        {value && (
          <UITable.Column as="th" hiddenXs>
            Valor
          </UITable.Column>
        )}
      </tr>
    </UITable.Header>
    <tbody>
      {coins
        .slice()
        .sort(sortCoins(orderBy))
        .map((coin, index) => (
          <UITable.Row key={index}>
            <BorderedColumn>
              <UIIcon.CoinName
                id={coin.id}
                name={coin.name}
                sizeXs="24"
              ></UIIcon.CoinName>
            </BorderedColumn>

            {allocation && (
              <BorderedColumn>
                <UIFilter.Percent
                  value={coin.allocation || coin.value.allocation}
                />
              </BorderedColumn>
            )}

            {amount && (
              <BorderedColumn lastXs>
                {coin.amount}&nbsp;{coin.symbol}
              </BorderedColumn>
            )}

            {tx && (
              <BorderedColumn lastXs>
                <TxLink
                  href={coin.order.explorer + coin.order.tx}
                  alt="TX da transação"
                  target="_blank"
                >
                  {coin.order.tx.substr(0, 10)}...
                </TxLink>
              </BorderedColumn>
            )}

            {price && (
              <BorderedColumn>
                <UIFilter.Currency value={coin.value.price} />
              </BorderedColumn>
            )}

            {cost && (
              <BorderedColumn>
                <UIFilter.Currency value={coin.value.cost} />
              </BorderedColumn>
            )}

            {value && (
              <BorderedColumn hiddenXs>
                <UIFilter.Currency value={coin.value.current} />
              </BorderedColumn>
            )}
          </UITable.Row>
        ))}
    </tbody>
  </Table>
);

export default observer(UITableDistribution);
