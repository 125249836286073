import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 84px;
`

const Label = styled.label`
  display: flex;
  align-items: center;
  color: var(--grey200);
  font-size: 0.75em;
  height: 24px;
`

const Select = styled.select`
  padding: 11px 5px;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #c9c9c9;
  outline: none;
  color: var(--greyish-brown);
  border-radius: 0 !important;
`

const UIFormSelect = ({ children, label, ...props }) =>
  <Wrapper>
    <Label>{label}</Label>
    <Select {...props}>{children}</Select>
  </Wrapper>

export default UIFormSelect
