import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { device } from '../../../constants'

const getIcon = (id, size) => {
  let icon
  try {
    icon = require(`../../../static/images/coins/${id}.svg`)
  }
  catch {
    try {
      icon = require(`../../../static/images/coins/${id}.png`)
    }
    catch {
      icon = `https://cdn.investtor.io/coins/${size}x${size}/${id}.png`
    }
  }

  return icon
}

const UIIconCoin = observer(
  styled.img.attrs(({ id, name, size = 32 }) => ({
    alt: name,
    title: name,
    src: getIcon(id, size),
    width: size,
    height: size,
  }))`
    margin: 0 5px;
    ${({ size, sizeXs }) => sizeXs && `
      width: ${sizeXs}px;
      height: ${sizeXs}px;

      @media ${device.laptop} {
        width: ${size || 32}px;
        height: ${size || 32}px;
      }
    `}
  `
)

const CoinNameWrapper = observer(
  styled.div`
    display: flex;
    align-items: center;
    font-size: 0.9375em;
    font-weight: bold;
    color: var(--greyish-brown);
  `
)

const UIIconCoinName = observer(
  props =>
    <CoinNameWrapper>
      <UIIconCoin {...props}></UIIconCoin>
      {props.name}
    </CoinNameWrapper>
)

export default UIIconCoin

export {
  UIIconCoinName
}
