import React from 'react'
import { observer } from 'mobx-react';
import Pending from './Pending'
import Realized from './Realized'

const ModalDeposit = ({ transaction }) =>
  transaction.pending
    ? <Pending {...{ transaction }} />
    : <Realized {...{ transaction }} />

export default observer(ModalDeposit)
