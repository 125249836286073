import React from "react";
import styled from "styled-components";
import { compose } from "recompose";
import { inject, observer } from "mobx-react";
import { NavLink } from "react-router-dom";

import { routes } from "../../../constants";
import UISidebarAccountStatus from "./AccountStatus";

import SettingsIcon from "../../../static/images/sidebar/settings.svg";
import UserNoPicture from "../../../static/images/sidebar/no_picture.svg";
import DownloadIcon from "../../../static/images/navbar/download-blue.svg";
import exitIcon from "../../../static/images/sidebar/exit.svg";
import helpIcon from "../../../static/images/sidebar/help.svg";

const Nav = styled.nav`
  z-index: 1000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 270px;
  background-color: var(--white);

  /* TODO: Criar animação de menu */
  /* transform: translateX(-100%);
  transition: transform 400ms ease-in; */

  /* .show-up {
    transform: translateX(0);
  } */
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
  height: 100px;
  width: calc(100% - 15px);
  background-color: var(--darkish-purple);
`;

const UserAvatar = styled.img.attrs(props => ({
  src: props.src || UserNoPicture
}))`
  width: 60px;
  height: 60px;
  background-color: var(--deep-lavender);
  border-radius: 50%;
`;

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--white);
  margin-left: 10px;
`;

const UserName = styled.span`
  font-weight: bold;
  line-height: 1.5;
`;

const UserEmail = styled.span`
  font-size: 0.8125em;
  line-height: 1.85;
`;

const Menu = styled.ul`
  list-style: none;
  padding: 0;
  margin-left: 15px;

  a {
    text-decoration: none;
    color: var(--brownish-grey);
  }
`;

const MenuItem = styled.li`
  display: flex;
  align-items: center;
  margin: 8px 0;
  font-weight: bold;

  ${({ hide }) =>
    hide &&
    `
    display: none;
  `}

  &::before {
    content: url(${props => props.icon});
    margin-right: 10px;
  }
`;

const UISidebarMobile = ({
  userStore: { user, hasLastYearBalance },
  sessionStore,
  navbarStore: { toggleSidebar }
}) => (
  <Nav>
    <Header>
      {!!user && !!user.name && <UserAvatar />}
      <UserDetails>
        {!!user && !!user.name ? (
          <React.Fragment>
            <UserName>{user.name}</UserName>
            <UserEmail>{user.email}</UserEmail>
          </React.Fragment>
        ) : (
          <UISidebarAccountStatus />
        )}
      </UserDetails>
    </Header>
    <Menu>
      {/* <MenuItem icon={referIcon}>
        <MenuLink to={routes.REFER_CAMPAIGN}>
          Indicação
        </MenuLink>
      </MenuItem> */}
      <MenuItem icon={SettingsIcon} onClick={toggleSidebar}>
        <NavLink to={routes.ACCOUNT}>Dados pessoais</NavLink>
      </MenuItem>
      <MenuItem
        icon={DownloadIcon}
        onClick={toggleSidebar}
        hide={!hasLastYearBalance}
      >
        <NavLink to={routes.REPORT_IR}>Imposto de renda 2020</NavLink>
      </MenuItem>
      <MenuItem icon={helpIcon}>
        <a
          href="https://investtor.com/ajuda"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ajuda
        </a>
      </MenuItem>
      <MenuItem icon={exitIcon} onClick={sessionStore.signOut}>
        Sair
      </MenuItem>
    </Menu>
  </Nav>
);

export default compose(
  inject("userStore", "sessionStore", "navbarStore"),
  observer
)(UISidebarMobile);
