import React from 'react'
import styled from 'styled-components'
import { findDOMNode, createPortal } from 'react-dom'
import { UIOverlay } from '..'
import { device } from '../../../constants'

import CloseIcon from '../../../static/images/modal/close.svg'
import { compose } from 'recompose'
import { observer, inject } from 'mobx-react'

const ModalElem = styled.div`
  position: relative;
  margin: auto;
  height: auto;
  max-height: 70vh;
  z-index: 1000;
  background-color: var(--white);
  overflow: auto;
  padding: 20px;
  border-radius: 4px;

  ${({ noPadding }) => noPadding && `
    padding: 0 !important;
  `}

  ::-webkit-scrollbar {
    width: 0.3em;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--brownish-grey);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  ${({ centered }) => centered && `
    display: flex;
    flex-direction: column;
    align-items: center;
  `}

  @media ${device.laptop} {
    margin-top: 80px;
    width: ${({ medium }) => medium ? '420px' : '520px'};
    padding: 50px 40px;
  }
`

const Close = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  background: var(--grey100);
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 50%;
`

class Modal extends React.Component {

  componentDidUpdate(prevPros) {
    const scrollTop = this.props.body !== prevPros.body
    if (scrollTop) {
      const node = findDOMNode(this)
      node.scrollTop = 0
    }
  }

  render() {
    const { showClose, medium, modalStore, body } = this.props

    return (
      <ModalElem {...this.props}>
        {showClose && (
          <Close {...{ medium }} onClick={modalStore.doClose}>
            <img src={CloseIcon} alt="Fechar"></img>
          </Close>
        )}
        {body}
      </ModalElem>
    )
  }
}

const ModalPortal = ({ children }) =>
  createPortal(children, document.getElementById('modal'))

const UIModal = ({ modalStore }) => {
  const { props, ...modal } = modalStore.modal
  document.body.style.overflow = modal.show ? 'hidden' : 'auto'

  return (
    modal.show
      ? (
        <React.Fragment>
          <ModalPortal>
            <UIOverlay {...(props.backdrop && { onClick: modalStore.doClose })}></UIOverlay>
            <Modal {...{ modalStore, ...modal, ...props }} />
          </ModalPortal>
        </React.Fragment>
      ) : null
  )
}

export default compose(
  inject('modalStore'),
  observer
)(UIModal)
