const coinsPrice = (transaction, withdraw = false) => withdraw
  ? transaction.coins.map(coin => ({
    ...coin,
    value: {
      price: (coin.order.value * transaction.order.price) / coin.amount,
      cost: coin.order.value * transaction.order.price,
    }
  }))
  : transaction.coins.map(coin => ({
    ...coin,
    value: {
      price: (transaction.amount * coin.allocation) / coin.amount,
      cost: transaction.amount * coin.allocation
    }
  }))

export {
  coinsPrice,
}
