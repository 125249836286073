import React from 'react'
import { observer, inject } from 'mobx-react'
import { compose } from 'recompose'
import { Form, Field } from 'react-final-form'
import { UIModalElem, UIForm } from '../UI'
import { Email, Required, composeValidators, MinLength } from '../UI/FormValidate';

const changeEmail = userStore => values => userStore.changeEmail(values)

const ModalChangeEmail = ({ userStore, modalStore }) =>
  <React.Fragment>
    <UIModalElem.Title>Trocar email</UIModalElem.Title>

    <Form
      onSubmit={changeEmail(userStore)}
      render={({ handleSubmit, pristine, invalid }) => (
        <form onSubmit={handleSubmit}>
          <UIForm.LabelValue asInput label="Email atual" value={userStore.user.email} />
          <br /> {/* POG para não alterar os estilos do componente */}
          <Field
            name="email"
            validate={
              composeValidators(
                Required(),
                Email()
              )
            }
            render={({ input, meta }) => (
              <UIForm.Input
                {...input}
                {...meta}
                label="Novo email"
                placeholder="joao@email.com" />
            )}>
          </Field>

          <Field
            name="password"
            validate={
              composeValidators(
                Required(),
                MinLength(6, 'Sua senha deve ter ao menos 6 caracteres'),
              )
            }
            render={({ input, meta }) => (
              <UIForm.Input
                {...input}
                {...meta}
                type="password"
                autocomplete="current-password"
                label="Sua senha"
                placeholder="••••••" />
            )}>
          </Field>

          <UIModalElem.Actions
            onConfirm={{
              show: true,
              loading: userStore.changingEmail,
              message: 'Alterar',
              props: {
                type: 'submit',
                disabled: pristine || invalid || userStore.changingEmail,
              }
            }}
            onCancel={{
              show: true,
              click: modalStore.doClose,
              message: 'Cancelar',
            }} />
        </form>
      )}>
    </Form>

  </React.Fragment>

export default compose(
  inject('userStore', 'modalStore'),
  observer,
)(ModalChangeEmail)
