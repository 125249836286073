import { decorate, observable, action } from "mobx"
import { isMobile } from "react-device-detect"

class NavbarStore {
  title = null
  showSidebar = false

  constructor(rootStore) {
    this.rootStore = rootStore
  }

  setTitle = title => this.title = title
  toggleSidebar = () => {
    if (!isMobile) return

    this.showSidebar = !this.showSidebar
  }

}

decorate(NavbarStore, {
  title: observable,
  showSidebar: observable,
  setTitle: action,
  toggleSidebar: action,
})

export default NavbarStore
