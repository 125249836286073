import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { UICard, UIFilter, UITooltip } from '../UI'

import ArrowNegative from '../../static/images/arrows/negative.svg'
import ArrowPositive from '../../static/images/arrows/positive.svg'

const Value = styled.span`
  display: flex;
  justify-content: flex-start;
  font-size: 1.625em;
  font-weight: bold;
  margin-bottom: 10px;

  ${({ value, hideArrow }) => (!hideArrow && value !== 0) && `
    &::before {
      content: url(${value > 0 ? ArrowPositive : ArrowNegative});
      margin-top: 3px;
      margin-right: 8px;
    }`
  };
`

const Label = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 0.9375em;
`

const ResumeCard = ({ tooltip, value, label, hideArrow, currency = true }) =>
  <UICard>
    <Value {...{ value, hideArrow }}>
      {
        currency
          ? <UIFilter.Currency value={value} absolute />
          : <UIFilter.Percent value={value} />
      }
    </Value>
    <Label>
      {label} {tooltip && <UITooltip.Help>{tooltip}</UITooltip.Help>}
    </Label>
  </UICard>

export default observer(ResumeCard)
