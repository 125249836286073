import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { UIIcon } from '..'

const Coins = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img:first-child {
    margin-left: 0;
  }
`

const ExtraCoins = styled.span`
  width: 30px;
  font-weight: bold;
  text-align: right;
`

const UICardCoinList = ({ coins, limit = 5 }) => {
  const visibleCoins = coins.slice(0, limit)

  return (
    <Coins>
      {
        visibleCoins.map((coin, index) => (
          <UIIcon.Coin small key={index} id={coin.id} name={coin.name}></UIIcon.Coin>
        ))
      }
      {
        coins.length > limit && (
          <ExtraCoins>+{coins.length - limit}</ExtraCoins>
        )
      }
    </Coins>
  )
}

export default observer(UICardCoinList)
