import React from 'react'
import { isMobile } from 'react-device-detect'
import { Form, Field } from 'react-final-form'
import { compose } from 'recompose'
import { inject, observer } from 'mobx-react'
import { composeValidators, Required } from 'components/UI/FormValidate'
import { UIForm, UIGrid, UIButton, UILoading } from 'components/UI'
import { states } from '../../../constants'

const onSubmit = userContactStore => values =>
  userContactStore.add(values)

const AccountFormContact = ({ userContactStore }) => {
  const { contact } = userContactStore

  return (
    <Form
      onSubmit={onSubmit(userContactStore)}
      initialValues={contact}
      render={({ handleSubmit, pristine, invalid }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="phone"
            validate={composeValidators(
              Required()
            )}
            render={({ input, meta }) => (
              <UIForm.Input
                {...{ ...input, ...meta }}
                autocomplete="tel"
                label="Celular (com DDD)" />
            )} />
          <Field
            name="address.cep"
            validate={composeValidators(
              Required()
            )}
            render={({ input, meta }) => (
              <UIForm.Input
                {...{ ...input, ...meta }}
                autocomplete="postal-code"
                label="CEP" />
            )} />
          <UIGrid.Row>
            <UIGrid.Column md={10}>
              <Field
                name="address.street"
                validate={composeValidators(
                  Required()
                )}
                render={({ input, meta }) => (
                  <UIForm.Input
                    {...{ ...input, ...meta }}
                    autocomplete="street-address"
                    label="Endereço" />
                )} />
            </UIGrid.Column>
            <UIGrid.Column md={2}>
              <Field
                name="address.number"
                validate={composeValidators(
                  Required()
                )}
                render={({ input, meta }) => (
                  <UIForm.Input
                    {...{ ...input, ...meta }}
                    label="Número" />
                )} />
            </UIGrid.Column>
          </UIGrid.Row>
          <UIGrid.Row>
            <UIGrid.Column md={6}>
              <Field
                name="address.complement"
                render={({ input, meta }) => (
                  <UIForm.Input
                    {...{ ...input, ...meta }}
                    label="Complemento" />
                )} />
            </UIGrid.Column>
            <UIGrid.Column md={6}>
              <Field
                name="address.neighborhood"
                validate={composeValidators(
                  Required()
                )}
                render={({ input, meta }) => (
                  <UIForm.Input
                    {...{ ...input, ...meta }}
                    label="Bairro" />
                )} />
            </UIGrid.Column>
          </UIGrid.Row>
          <UIGrid.Row>
            <UIGrid.Column md={6}>
              <Field
                name="address.city"
                validate={composeValidators(
                  Required()
                )}
                render={({ input, meta }) => (
                  <UIForm.Input
                    {...{ ...input, ...meta }}
                    autocomplete="address-level2"
                    label="Cidade" />
                )} />
            </UIGrid.Column>
            <UIGrid.Column md={6}>
              <Field
                name="address.state"
                validate={composeValidators(
                  Required()
                )}
                render={({ input, meta }) => (
                  <UIForm.Select
                    {...input}
                    {...meta}
                    label="Estado"
                    autocomplete="address-level1">
                    <option></option>
                    {
                      states.map((state, index) => (
                        <option
                          key={index}
                          value={state.symbol}>
                          {state.name}
                        </option>
                      ))
                    }
                  </UIForm.Select>
                )} />
            </UIGrid.Column>
          </UIGrid.Row>
          <UIButton type="submit" primary large fullWidth={isMobile} width={215} disabled={pristine || invalid || userContactStore.updating}>
            <UILoading.Button loading={userContactStore.updating}>
              Salvar
            </UILoading.Button>
          </UIButton>
        </form>
      )} />
  )
}

export default compose(
  inject('userContactStore'),
  observer,
)(AccountFormContact)
