import React from 'react'
import { compose } from 'recompose'
import { observer } from 'mobx-react'
import withAuthorization from '../components/withAuthorization'

import MyInvestmentDetailsPage from '../components/MyInvestmentDetails/Page'

const ScreensMyInvestmentDetails = () =>
  <MyInvestmentDetailsPage></MyInvestmentDetailsPage>

const userIsAuthenticated = auth => !!auth

export default compose(
  withAuthorization(userIsAuthenticated),
  observer,
)(ScreensMyInvestmentDetails)
