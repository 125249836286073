import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'

const Button = styled.button`
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
`

const UIButtonIconOnly = ({ icon, alt, ...props }) =>
  <Button {...props}>
    <img src={icon} alt={alt}></img>
  </Button>

export default observer(UIButtonIconOnly)
