import React, { useEffect } from "react";
import styled from "styled-components";
import { compose } from "recompose";
import { inject } from "mobx-react";

import { device } from "../../constants";
import { UITabs } from "../UI";
import * as AccountForm from "./Form";

const Title = styled.h1`
  display: none;
  margin-top: 0;

  @media ${device.laptop} {
    display: block;
    margin-bottom: 30px;
  }
`;

const AccountPage = ({ navbarStore }) => {
  useEffect(() => {
    navbarStore.setTitle("Dados cadastrais");
  }, [navbarStore]);

  return (
    <div>
      <Title>Dados cadastrais</Title>

      <UITabs.Wrapper>
        <UITabs.List>
          <UITabs.Tab>Dados pessoais</UITabs.Tab>
          <UITabs.Tab>Dados de contato</UITabs.Tab>
        </UITabs.List>

        <UITabs.Panel>
          <AccountForm.Personal />
        </UITabs.Panel>
        <UITabs.Panel>
          <AccountForm.Contact />
        </UITabs.Panel>
      </UITabs.Wrapper>
    </div>
  );
};

export default compose(inject("navbarStore"))(AccountPage);
