import styled, { css } from 'styled-components'
import { device } from '../../../constants'

import RightBlueArrow from '../../../static/images/arrows/right-blue.svg'

const borderLeft = css`
  border-left-style: solid;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`

const borderRight = css`
  border-right-style: solid;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`

const borderLeftMobile = css`
  border-left-style: solid;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding-left: 10px;

  @media ${device.tablet} {
    border-left-style: none;
  }
`

const borderRightMobile = css`
  border-right-style: solid;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;

  @media ${device.tablet} {
    border-right-style: none;
  }
`

const UITableColumn = styled.td`
  color: var(--greyish-brown);

  ${({ hiddenXs }) => hiddenXs && `
    display: none;
    @media ${device.tablet} {
      display: table-cell;
    }
  `}

  ${({rightArrow}) => rightArrow && `
    &::before {
      content: url(${RightBlueArrow});
    }
  `}

  ${({ bordered, lastXs, firstXs }) => bordered && `
    border-color: var(--white-two);
    border-width: 2px;
    border-bottom-style: solid;
    border-top-style: solid;

    ${firstXs ? borderLeftMobile : ''}
    ${lastXs ? borderRightMobile : ''}

    &:first-child {
      ${borderLeft}
      padding-left: 10px;

      @device ${device.laptop} {
        padding-left: 15px;
      }
    }

    &:last-child {
      ${borderRight}
    }
  `}
`

export default UITableColumn
