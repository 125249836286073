import styled from 'styled-components'

const UIModalFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-left: -40px;
  margin-bottom: -40px;
  margin-right: -40px;
  border-top: 1px solid var(--grey100);
  width: calc(100% + 80px);
  height: 80px;
  text-align: center;
`

const UIModalIcon = styled.img`
  display: flex;
  margin: auto;
`

const UIModalTitle = styled.h1`
  margin-top: 0;
  text-align: ${({ direction }) => direction || 'left'};
`

export {
  UIModalFooter,
  UIModalIcon,
  UIModalTitle,
}
