import { autorun, decorate, observable, action } from 'mobx'
import { db, firebaseHelper } from '../firebase'

class UserTransactionsStore {
  fetching = true
  completed = null
  pending = null

  constructor(uid, strategyId) {
    autorun(() => {
      db.users.transactions.getAllByStrategy(uid, strategyId)
        .onSnapshot(action(snapshot => {
          const transactions = firebaseHelper.docsToArray(snapshot)
          this.completed = transactions.filter(transaction => !transaction.pending).sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate())
          this.pending = transactions.filter(transaction => transaction.pending).sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate())
          this.fetching = false
        }))
    })
  }
}

decorate(UserTransactionsStore, {
  fetching: observable,
  completed: observable,
  pending: observable,
})

export default UserTransactionsStore
