export const name = process.env.REACT_APP_ENV
export const isProduction = name === 'production'
export const isDevelopment = name === 'development'
export const isStaging = name === 'staging'

export const config = {
  domain: process.env.REACT_APP_DOMAIN,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  actId: process.env.REACT_APP_ACT_ID,
}
