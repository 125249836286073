import styled from "styled-components"

const UIGridRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  &::after {
    content: '';
    clear: both;
  }
`

export default UIGridRow
