import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { UICard, UIButtons, UICardElem, UIBadge } from '../UI'
import { routes } from '../../constants'
import UIFilterCurrency from '../UI/Filter/Currency'

const Title = styled.h3`
  text-align: center;
  margin-top: 0;
`

const BalanceAndProfit = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.9375em;
  padding-top: 30px;
  margin-top: 30px;
  margin-bottom: 7px;
  border-top: 1px solid var(--white-two);
  width: calc(100% + 40px);
`

const Balance = styled.h2`
  margin: 0;
`

const Profit = styled.p`
  margin: 0;
  margin-bottom: 20px;
`

const Status = styled(UIBadge.Status)`
  margin-bottom: 30px;
`

const ShowDetails = styled(UIButtons.Link)`
  margin-top: auto;
`

class MyInvestmentsCard extends React.Component {

  render() {
    const { investment } = this.props

    return (
      <UICard>
        <Title>{investment.strategy.title}</Title>
        <UICardElem.CoinList coins={investment.coins} />
        <BalanceAndProfit>
          Saldo e Rendimentos
        </BalanceAndProfit>
        <Balance>
          <UIFilterCurrency value={investment.balance.current} />
        </Balance>
        <Profit>
          <UIFilterCurrency value={investment.balance.profit} />
        </Profit>
        {investment.waitingDeposit && <Status secondary>Aguardando depósito</Status>}
        {investment.procesingWithdraw && <Status>Processando resgate</Status>}
        <ShowDetails large primary fullWidth to={`${routes.MY_INVESTMENTS}/${investment.id}`}>Detalhes</ShowDetails>
      </UICard>
    )
  }
}

export default observer(MyInvestmentsCard)
