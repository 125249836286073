import React from 'react'
import styled from 'styled-components'
import { compose } from 'recompose'
import { observer, inject } from 'mobx-react'
import MyInvestmentsCard from './Card'
import { device } from '../../constants'
import { UIGrid, UILoading } from '../UI'

const Title = styled.h1`
  display: none;
  margin-top: 0;

  @media ${device.laptop} {
    display: block;
    margin-bottom: 30px;
  }
`

const MyInvestmentsPage = ({ userInvestmentsStore, navbarStore }) => {
  navbarStore.setTitle('Meus investimentos')
  return (
    <UILoading.Page loading={userInvestmentsStore.fetching}>
      <Title>Meus Investimentos</Title>
      <UIGrid.Row>
        {
          userInvestmentsStore.investments.length
            ? userInvestmentsStore.investments.map((investment, index) => (
              <UIGrid.Column key={index} sm="6" lg="4" xl="3">
                <MyInvestmentsCard investment={investment}></MyInvestmentsCard>
              </UIGrid.Column>
            )) : 'Você ainda não possui nenhum investimento'
        }
      </UIGrid.Row>
    </UILoading.Page>
  )
}

export default compose(
  inject('userInvestmentsStore', 'navbarStore'),
  observer
)(MyInvestmentsPage)
