import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import VisibilityStore from "../../../stores/VisibilityStore";

const Dropdown = styled.div`
  position: relative;
  display: inline-block;
`;

const Items = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: column;
  position: absolute;
  background: white;
  filter: drop-shadow(0 1px 6px rgba(0, 0, 0, 0.3));
  border-radius: 4px;
  right: 0;
  z-index: 1;
  padding: 20px;
  margin-top: 5px;

  &::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    top: -8px;
    right: 10px;
    border-style: solid;
    border-width: 0 9px 8px 9px;
    border-color: transparent transparent white transparent;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--brownish-grey);
  cursor: pointer;
  white-space: nowrap;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:before {
    content: url(${({ icon }) => icon});
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
`;

const store = new VisibilityStore();

const UIButtonDropdown = ({ Button, options }) => (
  <Dropdown>
    <Button onMouseEnter={store.show} onMouseLeave={store.hide} />
    <Items
      show={store.visible}
      onMouseEnter={store.show}
      onMouseLeave={store.hide}
    >
      {options
        .filter(option => !option.hide)
        .map(({ text, ...option }, index) => (
          <Item key={index} {...option}>
            {text}
          </Item>
        ))}
    </Items>
  </Dropdown>
);

export default observer(UIButtonDropdown);
