import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { UIButton, UIFilter, UITooltip } from '..'
import { device } from '../../../constants'

const Title = styled.h3`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 100%;

  ${({ vertical }) => vertical && `
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: unset;
  `}
`

const Label = styled.label`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  color: var(--greyish-brown);
  opacity: 0.7;
  font-size: 0.75em;

  @media ${device.mobileS} {
    width: 100%;
  }

  @media ${device.laptop} {
    max-width: ${({ vertical }) => vertical ? 'unset' : '350px'};
    margin-right: ${({ vertical }) => vertical ? 0 : '25px'};
    width: ${({ vertical }) => vertical ? '100%' : 'auto'};
  }
`

const Input = styled.input`
  background: transparent;
  border: none;
  border-bottom: 2px solid var(--grey200);
  color: var(--greyish-brown);
  opacity: 1;
  font-size: 1.333333em;
  padding: 8px 5px 5px;

  &:focus {
    outline: none;
  }
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 15px;
`

const ValueButton = styled(UIButton).attrs(props => ({
  medium: true
}))`
  font-size: 0.75em;
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
    display: none;
  }

  &:nth-last-child(-n+2) {
    display: none;

    @media ${device.tablet} {
      display: flex;
    }
  }

  @media ${device.laptop} {
    width: 84px;
  }
`

const ErrorMessage = styled.span.attrs(() => ({
  role: 'alert',
}))`
  padding-top: 5px;
  color: var(--lipstick);
`

const UIFormsInvestValue = ({ onChange, minValue, currentValue, ...props }) => {
  const handleChangeValue = value => onChange(value)
  const handleOnChange = event => onChange(event.target.value)
  const isActive = value => currentValue === parseFloat(value)

  const invalid = currentValue < minValue

  return (
    <React.Fragment>
      <Title>
        Quanto você deseja investir?
        <UITooltip.Help place="bottom">
          O valor mínimo para começar a investir bem através do Investtor é <UIFilter.Currency value={minValue} />.
        </UITooltip.Help>
      </Title>
      <Wrapper {...props}>
        <Label {...props}>
          Valor
          <Input
            type="number"
            min="500"
            value={currentValue}
            onChange={handleOnChange} />
          {invalid && (
            <ErrorMessage>
              O valor mínimo para a estratégia é <UIFilter.Currency value={minValue} />
            </ErrorMessage>
          )}
        </Label>
        <Buttons>
          {
            [1, 2, 6, 10, 20].map((mult, index) => {
              const value = minValue * mult

              return (
                <ValueButton
                  key={index}
                  medium
                  onClick={() => handleChangeValue(value)}
                  primary={isActive(value)}>
                  <UIFilter.Currency {...{ value }} />
                </ValueButton>
              )
            })
          }
        </Buttons>
      </Wrapper>
    </React.Fragment>
  )
}

export default observer(UIFormsInvestValue)
