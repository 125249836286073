import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { Panel } from '@bumaga/tabs'
import { UICard } from '..'

const StyledPanel = styled(UICard)`
  border-top-left-radius: 0;
  padding: 32px;
  align-items: unset;
`

const UITabPanel = ({ children }) =>
  <Panel as={StyledPanel}>
    <StyledPanel>{children}</StyledPanel>
  </Panel>

export default observer(UITabPanel)
