import React from 'react';

import withAuthorization from '../components/withAuthorization';

import MyInvestmentsPage from '../components/MyInvestments/Page';

const ScreensMyInvestments = () =>
  <MyInvestmentsPage></MyInvestmentsPage>

const userIsAuthenticated = auth => !!auth;

export default withAuthorization(userIsAuthenticated)(ScreensMyInvestments);
