import React from 'react'
import { useTabState } from '@bumaga/tabs'
import styled from 'styled-components'
import { device } from '../../../constants'

const Tab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  padding: 0 15px;
  text-transform: uppercase;
  color: var(--greyish-brown);
  font-size: 0.75em;
  font-weight: bold;
  text-align: center;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  margin-right: 1px;

  @media ${device.mobileL} {
    padding: 0 32px;
  }

  ${({ isActive }) => isActive
    ? `
      background-color: var(--white);
    ` : `
      cursor: pointer;
      background-color: var(--grey100);

      &:hover {
        opacity: 0.5;
      }
    `}
`

const UITab = ({ children }) => {
  const { isActive, onClick } = useTabState()

  return (
    <Tab {...{ onClick, isActive }} >
      {children}
    </Tab>
  )
}

export default UITab
