import React from 'react'
import styled from 'styled-components'
import { compose } from 'recompose'
import { inject, observer } from 'mobx-react'
import { UIModalElem } from '..'

const Message = styled.p`
  text-align: ${({ textAlign }) => textAlign};
`

const Info = styled.h1`
  text-align: center;
`

const getParagraphs = (message, textAlign) => typeof message === 'string'
  ? <Message {...{ textAlign }}>{message}</Message>
  : message.map((m, index) => <Message key={index}>{m}</Message>)

const UIModalInfo = ({
  modalStore,
  title,
  info,
  icon,
  message,
  button,
  action,
  showCancel,
  textAlign = 'center',
  cancelButton = 'Cancelar'
}) =>
  <React.Fragment>
    {title && <UIModalElem.Title {...{ textAlign }}>{title}</UIModalElem.Title>}
    {icon && <UIModalElem.Icon src={icon} alt="" />}
    {message && getParagraphs(message, textAlign)}
    {info && <Info>{info}</Info>}

    {action && (
      <UIModalElem.Actions
        onConfirm={{
          show: !!action,
          click: action,
          message: button
        }}
        onCancel={{
          show: !!showCancel,
          click: modalStore.doClose,
          message: cancelButton || 'Cancelar'
        }} />
    )}
  </React.Fragment>

export default compose(
  inject('modalStore'),
  observer
)(UIModalInfo)
