import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import * as helpers from "../helpers";
import { UIModalElem, UIForm, UIFilter, UITable } from "../../../UI";

const Title = styled.h3`
  margin-top: 35px;
`;

const ModalWCryptoWithdraw = ({ transaction }) => (
  <React.Fragment>
    <UIModalElem.Title>Transferência realizada</UIModalElem.Title>

    <UITable.Distribution
      coins={transaction.coins}
      amount
      tx
      orderBy={{ symbol: true }}
    />
  </React.Fragment>
);

const ModalTransactionWithdrawRealized = ({ transaction }) => {
  const coins = helpers.coinsPrice(transaction, true);

  return (
    <React.Fragment>
      <UIModalElem.Title>Resgate realizado</UIModalElem.Title>

      <Title>Valores e data</Title>

      <UIForm.LabelValue
        label="Valor bruto"
        value={<UIFilter.Currency value={transaction.values.amount} />}
      ></UIForm.LabelValue>

      <UIForm.LabelValue
        label="Taxa bancária"
        value={<UIFilter.Currency value={transaction.values.bankFee} />}
      ></UIForm.LabelValue>

      <UIForm.LabelValue
        label="Valor líquido"
        value={<UIFilter.Currency value={transaction.values.netBalance} />}
      ></UIForm.LabelValue>

      <UIForm.LabelValue
        label="Data"
        value={
          <UIFilter.DateTime
            value={transaction.closedAt.toDate()}
            format="DD/MM/YYYY - HH:mm"
          />
        }
      ></UIForm.LabelValue>

      <Title>Dados bancários</Title>

      <UIForm.LabelValue label="Banco" value={transaction.bank.name} />
      <UIForm.LabelValue label="Agência" value={transaction.bank.agency} />
      <UIForm.LabelValue
        label="Conta corrente"
        value={`${transaction.bank.account}-${transaction.bank.digit}`}
      />

      <Title>Detalhes da venda</Title>

      <UITable.Distribution
        {...{ coins }}
        price
        amount
        cost
        orderBy={{ cost: true }}
      />
    </React.Fragment>
  );
};

const ModalWithdrawRealized = ({ transaction }) =>
  transaction.isCryptoWithdraw ? (
    <ModalWCryptoWithdraw transaction={transaction} />
  ) : (
    <ModalTransactionWithdrawRealized transaction={transaction} />
  );

export default observer(ModalWithdrawRealized);
