import { configure } from 'mobx'

import ActiveCampaignStore from './ActiveCampaignStore'
import ModalStore from './ModalStore'
import NavbarStore from './NavbarStore'
import SessionStore from './SessionStore'
import StrategiesStore from './StrategiesStore'
import UserContactStore from './UserContactStore'
import UserInvestmentsStore from './UserInvestmentsStore'
import UserStore from './UserStore'

configure({ enforceActions: 'observed' })

class RootStore {
  constructor() {
    this.activeCampaignStore = new ActiveCampaignStore()
    this.modalStore = new ModalStore(this)
    this.navbarStore = new NavbarStore(this)
    this.sessionStore = new SessionStore(this)
    this.strategiesStore = new StrategiesStore(this)
    this.userContactStore = new UserContactStore(this)
    this.userInvestmentsStore = new UserInvestmentsStore(this)
    this.userStore = new UserStore(this)
  }
}

const rootStore = new RootStore()

export default rootStore
