import React from 'react'
import * as Sentry from '@sentry/browser'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { observer, inject } from 'mobx-react'
import { compose } from 'recompose'
import { device } from '../../../constants'

const AppContainer = styled.div`
  display: grid;
  grid-template-areas:
    'header'
    'main'
    'footer';
  grid-template-rows: auto 1fr;
  background-color: var(--white-two);
  min-height: 100vh;

  @media ${device.laptop} {
    ${({ authenticated }) => authenticated
    ? `
        grid-template-areas:
          'menu header'
          'menu main'
          'menu footer';
      ` : `
        grid-template-areas: 'main';
      `}
  }
`

class UIAppContainer extends React.Component {

  state = {
    error: null
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key])
      })
      const { authUser } = this.props.sessionStore
      authUser && scope.setUser({
        uid: authUser.uid,
        email: authUser.email
      })
      Sentry.captureException(error)
    })
  }

  componentDidMount() {
    const { history, modalStore } = this.props
    this.unListen = history.listen(() => modalStore.doClose())
  }

  componentWillUnmount() {
    this.unListen()
  }

  render() {
    const { history, modalStore, ...props } = this.props
    return (
      this.state.error
        ? <a href="#" onClick={() => Sentry.showReportDialog()}>Reportar erro</a> //eslint-disable-line
        : <AppContainer {...props} />
    )
  }
}

export default compose(
  withRouter,
  inject('modalStore', 'sessionStore'),
  observer,
)(UIAppContainer)
