import React from 'react'
import styled from 'styled-components'

import DepositIcon from '../../../static/images/transactions/deposit.png'

const IconTransaction = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  ${({bonus}) =>
    (bonus && `color: var(--turquoise-blue);font-weight: 600;`)
  }

  &::before {
    margin-right: 10px;
    ${({ bonus }) =>
    (bonus && `content: url(${DepositIcon})`)
  };
  }
`

const UIIconTransactionRefer = ({ bonus, value }) =>
  <IconTransaction bonus={bonus}>
    {bonus ? 'Ganhou' : 'Pendente'} R$ {value}
  </IconTransaction>;

export default UIIconTransactionRefer
