import React from 'react';
import { observer } from 'mobx-react';
import { compose } from 'recompose';

import withAuthorization from '../components/withAuthorization';
import AccountPage from '../components/Account/Page';

const ScreensAccount = () =>
  <AccountPage />

const userIsAuthenticated = auth => !!auth;

export default compose(
  withAuthorization(userIsAuthenticated),
  observer
)(ScreensAccount);
