import { db, firebaseHelper } from '../firebase'
import { decorate, observable, action, when, computed } from 'mobx'

class UserContactStore {
  contact = {}
  updating = false

  constructor(rootStore) {
    this.rootStore = rootStore
    this.sessionStore = rootStore.sessionStore

    when(
      () => !!this.sessionStore.authUser,
      () => {
        this.uid = this.sessionStore.authUser.uid
        this.getCurrent()
      }
    )
  }

  get registered() {
    return !!Object.keys(this.contact).length
  }

  add = contact => {
    this.updating = true

    return db.users.contact.add(this.uid, contact)
      .then(action(res => {
        this.updating = false
        this.contact = contact
      }))
  }

  getCurrent = () =>
    db.users.contact.get(this.uid)
      .then(doc => {
        if (!doc.exists) return

        this.contact = firebaseHelper.docToObject(doc)
      })
}

decorate(UserContactStore, {
  registered: computed,
  contact: observable,
  updating: observable,
  add: action,
  getCurrent: action,
})

export default UserContactStore
