import React from 'react'
import styled from 'styled-components'
import { compose } from 'recompose'
import { inject, observer } from 'mobx-react'
import { Form, Field } from 'react-final-form'
import SignUpLink from '../SignUp/Link'
import PasswordForgetLink from '../Password/Forget/Link'
import { UIForm, UIButton, UILoading } from '../UI'
import { device } from '../../constants';
import { composeValidators, Required, Email } from '../UI/FormValidate';


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 250px;

  @media ${device.laptop} {
    width: 70%;
    max-width: 500px;
  }
`

const Title = styled.h1`
  margin-top: 0;
  margin-bottom: 70px;
`

const PasswordForget = styled.div`
  display: table;
  margin-left: auto;
  margin-top: 30px;
`

const SignInButton = styled(UIButton).attrs(() => ({
  large: true,
  primary: true,
  fullWidth: true,
  type: "submit",
}))`
  margin-top: 40px;
`

const SignUp = styled.div`
  margin-top: 60px;
`

const ErrorMessage = styled.p`
  color: var(--coral);
`

const onSubmit = sessionStore => ({ email, senha }) => sessionStore.signIn(email, senha)

const SignInForm = ({ sessionStore }) =>
  <Wrapper>
    <Title>Bem vindo de volta</Title>
    <Form
      onSubmit={onSubmit(sessionStore)}
      render={({ handleSubmit, pristine, invalid }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="email"
            validate={
              composeValidators(
                Required('Informe seu email'),
                Email(),
              )
            }
            render={({ input, meta }) => (
              <UIForm.Input {...{ ...input, ...meta }} label="Seu email" />
            )}
          ></Field>
          <Field
            name="senha"
            validate={
              Required('Informe sua senha')
            }
            render={({ input, meta }) => (
              <UIForm.Input {...{ ...input, ...meta }} type="password" label="Sua senha" />
            )}
          ></Field>
          <PasswordForget>
            <PasswordForgetLink>Esqueceu a senha?</PasswordForgetLink>
          </PasswordForget>
          <SignInButton disabled={pristine || invalid || sessionStore.signingIn}>
            <UILoading.Button loading={sessionStore.signingIn}>
              Entrar
            </UILoading.Button>
          </SignInButton>
          {!!sessionStore.authError && (
            <ErrorMessage>
              {sessionStore.authError}
            </ErrorMessage>
          )}
        </form>
      )}
    ></Form>
  </Wrapper>

export default compose(
  inject('sessionStore'),
  observer
)(SignInForm)
