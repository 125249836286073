import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react';
import { UIFilter, UILayout, UILoading, UITooltip } from '..';

const Wrapper = styled.div`
  min-width: 200px;
`

const Label = styled.h3`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 300;
  color: var(--greyish-brown);
  margin: 0;
`

const Value = styled.span`
  display: flex;
  align-content: center;
  font-weight: bold;
`

const UINavbarHeaderInfo = ({ label, value, variation, fetching, tooltip }) =>
  <Wrapper>
    <Label>
      {label}
      {
        tooltip && <UITooltip.Help>{tooltip}</UITooltip.Help>
      }
    </Label>
    <Value>
      <UILoading.Inline loading={fetching} message="calculando...">
        <UIFilter.Currency value={value} />&nbsp;
        {
          !!variation && (
            <UILayout.Variation value={variation} parentesis>
              <UIFilter.Percent value={variation} />
            </UILayout.Variation>
          )
        }
      </UILoading.Inline>
    </Value>
  </Wrapper>

export default observer(UINavbarHeaderInfo)
