import React from 'react'
import { observable, action, decorate } from 'mobx'
import UIModalInfo from '../components/UI/Modal/Info'

const INITIAL_STATE = {
  show: false,
  body: null,
  props: null,
}

class ModalStore {
  modal = INITIAL_STATE

  constructor(rootStore) {
    this.rootStore = rootStore
  }

  doOpen = (body, props = { backdrop: true, showClose: true }) => {
    document.getElementById('modal').classList.add('modal-portal')
    this.modal = {
      show: true,
      body,
      props: {
        ...props,
        backdrop: props.backdrop === undefined ? true : props.backdrop,
        showClose: props.showClose === undefined ? true : props.showClose,
      },
    }
  }

  doClose = () => {
    document.getElementById('modal').classList.remove('modal-portal')
    this.modal = INITIAL_STATE
  }

  showInfo = (body, props) => {
    this.doOpen(<UIModalInfo {...body} />, props)
  }

}

decorate(ModalStore, {
  modal: observable,
  doClose: action,
  doOpen: action,
})

export default ModalStore
