import { functions } from "../firebase";
import { decorate, observable, action, computed } from "mobx";

class StrategyStore {
  fetchingPerformance = true;
  details = {};
  performance = {};

  get isActive() {
    return !this.details.disabled;
  }

  getPerformance = () =>
    functions
      .strategyPerformance(this.details)
      .then(res => res.data)
      .then(
        action(res => {
          this.performance = { ...res.balance };
          this.details.coins = res.coins;
          this.fetchingPerformance = false;
        })
      );

  constructor(strategy) {
    this.details = strategy;
    this.getPerformance();
  }
}

decorate(StrategyStore, {
  fetchingPerformance: observable,
  performance: observable,
  details: observable,
  getPerformance: action,
  isActive: computed
});

export default StrategyStore;
