import React from 'react'
import JsxParser from 'react-jsx-parser'
import { observer } from 'mobx-react'
import { UIModalElem, UITable } from '../UI'
import { EnviromentHelper } from '../../helpers'

const ModalStrategyCoins = ({ coins, title, description }) =>
  <React.Fragment>
    <UIModalElem.Title>{title}</UIModalElem.Title>
    <JsxParser
      showWarnings={!EnviromentHelper.isProduction}
      renderInWrapper={false}
      jsx={description} />
    <h3>Distribuição</h3>
    <UITable.Distribution allocation coins={coins} />
  </React.Fragment>

export default observer(ModalStrategyCoins)





