import React from "react";
import styled from "styled-components";
import { compose } from "recompose";
import { when } from "mobx";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import ResumeCard from "./ResumeCard";
import DistributionCard from "./DistributionCard";
import TransactionCard from "./Transaction/Card";
import { UIGrid, UIIcon, UILoading, UIButtons } from "../UI";
import { device } from "../../constants";
import * as Modal from "../Modal";

const Title = styled.h1`
  display: none;

  @media ${device.laptop} {
    display: block;
    margin-right: auto;
    margin-bottom: 35px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  @media ${device.laptop} {
    margin-right: 20px;
    margin-bottom: 0;
    justify-content: unset;
  }
`;

const ModalButton = compose(
  inject("modalStore", "strategiesStore", "userInvestmentsStore", "userStore"),
  observer
)(
  ({
    modalStore,
    strategiesStore,
    userInvestmentsStore,
    userStore,
    deposit,
    ...props
  }) => {
    const investment = userInvestmentsStore.current;
    const { details, isActive } = strategiesStore.find(
      investment.strategy.id
    ).store;

    const disabled =
      (deposit && !isActive) ||
      (!deposit &&
        (!investment.balance.current || investment.procesingWithdraw));
    const title =
      disabled &&
      (deposit
        ? "Esta estratégia não está aberta para novos investimentos."
        : investment.procesingWithdraw
        ? "Sua solicitação de resgate está sendo processada."
        : "Não há saldo para resgate.");

    const doOpenModal = () =>
      deposit
        ? modalStore.doOpen(<Modal.Invest strategyId={details.id} />)
        : modalStore.showInfo({
            action: () =>
              modalStore.doOpen(<Modal.Withdraw {...{ investment }} />, {
                backdrop: false
              }),
            textAlign: "left",
            showCancel: true,
            button: "Sim, quero avançar",
            title: "Deseja resgatar seu investimento?",
            message: [
              `Seu resgate será creditado em sua conta bancária em até 7 dias úteis a partir do dia da sua solicitação.`,
              `Só serão aceitos resgates para contas corrente do mesmo CPF cadastrado na conta da Investtor. Não aceitamos resgates enviados para contas de terceiros ou CNPJ (Pessoa Jurídica).`,
            ]
          });

    const buttonProps = {
      onClick: doOpenModal,
      large: true,
      primary: true,
      disabled,
      ...props,
      spanStyle: `
      button {
        width: 100%;
      }

      &:first-of-type:not(:last-child) {
        margin-right: 10px;
      }

      @media ${device.laptop} {
        justify-self: flex-end;
        width: 197px;
      }
    `
    };

    if (disabled) {
      buttonProps.tooltip = title;
    }

    return (
      <UIButtons.Tooltip {...buttonProps}>
        <UIIcon.Transaction type={{ deposit }} />
      </UIButtons.Tooltip>
    );
  }
);

class MyInvestmentDetailsPage extends React.Component {
  constructor(props) {
    super(props);

    const { id } = props.match.params;
    const { userInvestmentsStore, navbarStore } = props;
    const { fetching, fetchingCurrent, current } = userInvestmentsStore;

    userInvestmentsStore.setCurrent(id);
    navbarStore.setTitle("Detalhes");

    when(
      () => !fetching && !fetchingCurrent,
      () => {
        navbarStore.setTitle(current.strategy.title);
      }
    );
  }

  render() {
    const {
      current: investment,
      fetchingCurrent
    } = this.props.userInvestmentsStore;

    return (
      <UILoading.Page loading={fetchingCurrent}>
        {investment ? (
          <React.Fragment>
            <Header>
              <Title>Estratégia {investment.strategy.title}</Title>
              <ModalButton strategyId={investment.strategy.id} />
            </Header>
            <UIGrid.Row>
              <UIGrid.Column md="4">
                <ResumeCard
                  value={investment.balance.current}
                  label="Saldo bruto"
                  tooltip="Valor de venda estimado dos ativos neste momento."
                  hideArrow
                ></ResumeCard>
              </UIGrid.Column>
              <UIGrid.Column md="4">
                <ResumeCard
                  value={investment.balance.profit}
                  label="Valorização"
                ></ResumeCard>
              </UIGrid.Column>
              <UIGrid.Column md="4">
                <ResumeCard
                  value={investment.balance.performance}
                  label="Performance"
                  currency={false}
                ></ResumeCard>
              </UIGrid.Column>
              <UIGrid.Column lg="6" xl="4">
                <DistributionCard coins={investment.coins}></DistributionCard>
              </UIGrid.Column>
              <UIGrid.Column lg="6" xl="4">
                <TransactionCard strategy={investment}></TransactionCard>
              </UIGrid.Column>
            </UIGrid.Row>
          </React.Fragment>
        ) : (
          "Parece que você ainda não investiu nesta estratégia ou ela não existe."
        )}
      </UILoading.Page>
    );
  }
}

export default compose(
  withRouter,
  inject("userInvestmentsStore", "navbarStore"),
  observer
)(MyInvestmentDetailsPage);
