import React from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
`

const Banner = styled.div`
  flex: 1;
  background-color: var(--darkish-purple);
  background-image: url(${require(`../../../static/images/background/cover.png`)});
  background-size: cover;
  background-repeat: no-repeat;
`

const InvesttorLogo = styled.img.attrs(() => ({
  src: require('../../../static/images/logo-investtor-full.svg'),
  alt: 'Investtor'
}))`
  position: absolute;
  top: 60px;
  left: 60px;
`

const FormWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
`

const UIAppSignInUpPage = ({ form }) =>
  <Wrapper>
    {!isMobile &&
      <Banner>
        <a href="https://investtor.com" alt="Website investtor.com">
          <InvesttorLogo />
        </a>
      </Banner>
    }
    <FormWrapper>{form}</FormWrapper>
  </Wrapper>

export default UIAppSignInUpPage
