import { EnviromentHelper } from 'helpers';
import { functions } from '../firebase';

/* eslint-disable no-undef */
class ActiveCampaignStore {

  constructor() {
    // eslint-disable-next-line
    (function (e, t, o, n, p, r, i) { e.visitorGlobalObjectAlias = n; e[e.visitorGlobalObjectAlias] = e[e.visitorGlobalObjectAlias] || function () { (e[e.visitorGlobalObjectAlias].q = e[e.visitorGlobalObjectAlias].q || []).push(arguments) }; e[e.visitorGlobalObjectAlias].l = (new Date).getTime(); r = t.createElement("script"); r.src = o; r.async = true; i = t.getElementsByTagName("script")[0]; i.parentNode.insertBefore(r, i) })(window, document, "https://diffuser-cdn.app-us1.com/diffuser/diffuser.js", "vgo")

    vgo('setAccount', EnviromentHelper.config.actId)
    vgo('setTrackByDefault', true)
  }

  trackEvent(eventName, eventData = '') {
    functions.userTrackEvent(eventName, eventData)
  }

  setEmail(email) {
    if (email) {
      functions.userExistsOnAc()
      vgo('setEmail', email)
    }
    this.start()
  }

  start() {
    vgo('process')
  }

}

export default ActiveCampaignStore
