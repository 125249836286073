import {
  db,
  auth,
  firebaseError,
  firebaseHelper,
  functions,
} from "../firebase";
import { decorate, observable, action, computed } from "mobx";
import { PlatformHelper } from "../helpers";

class UserStore {
  user = {};
  kyc = {};
  refer = { main: "", child: "", clicks: 0 };
  annualBalance = {
    previousYear: {},
    lastYear: {},
  };
  creating = false;
  resetingPassword = false;
  changingEmail = false;
  loading = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.modalStore = rootStore.modalStore;
    this.sessionStore = rootStore.sessionStore;
  }

  get hasLastYearBalance() {
    return (
      Boolean(this.annualBalance.lastYear) &&
      Object.keys(this.annualBalance.lastYear).length > 0
    );
  }

  get firstName() {
    return !this.user.name ? "" : this.user.name.split(" ")[0];
  }

  get kycApproved() {
    return !!(this.kycInformed && this.kyc.approved);
  }

  get kycChecking() {
    return !!(this.kycInformed && this.kyc.checking);
  }

  get kycCompleted() {
    if (!this.kycInformed) return false;

    const nameInformed = !!this.user.name;
    const cpfInformed = !!this.user.cpf;
    const birthdayInformed = !!this.user.birthday;

    return nameInformed && cpfInformed && birthdayInformed;
  }

  get kycDenied() {
    return !!(this.kycInformed && !!this.kyc.denied);
  }

  get kycInformed() {
    return !!Object.keys(this.kyc).length;
  }

  get isAndroidUser() {
    return PlatformHelper.isTWA();
  }

  processesRefer = () => {
    const { authUser } = this.rootStore.sessionStore;

    return db.users.processes.getRefer(authUser.uid).onSnapshot(
      action((snapshot) => {
        if (snapshot.exists) {
          let objRefer = firebaseHelper.docToObject(snapshot);
          if (objRefer.main) {
            this.refer = objRefer;
            return true;
          }
        }

        return functions.userRefer();
      })
    );
  };

  createRefer = () => {
    if (!this.refer.main) {
      this.processesRefer();
    }
  };

  create = ({ email, password }) => {
    this.creating = true;
    return auth
      .doCreateUserWithEmailAndPassword(email, password)
      .then((res) => res.user)
      .then((user) => {
        let refer = window.localStorage.getItem("refer");
        this.rootStore.sessionStore.set(user);
        this.creating = false;
        functions.userNotifyRegistration({
          email,
          isAndroidUser: this.isAndroidUser,
        });

        if (refer) functions.userReferChild(refer, email);
      })
      .catch((err) => {
        this.sessionStore.setAuthError(
          firebaseError.getMessageByCode(err.code)
        );
        this.creating = false;
      });
  };

  changeEmail = ({ email, password }) => {
    this.changingEmail = true;
    auth
      .doSignInWithEmailAndPassword(this.user.email, password)
      .then(
        action((credentials) => {
          credentials.user.updateEmail(email);
          this.changingEmail = false;
        })
      )
      .catch((err) => {
        this.changingEmail = false;
      });
  };

  completeRegistration = (user) => {
    const { authUser } = this.rootStore.sessionStore;
    const userData = {
      ...user,
      uid: authUser.uid,
    };
    this.creating = true;
    return functions.userSetPersonalData(userData).then(
      action(() => {
        this.user = userData;
        this.creating = false;
      })
    );
  };

  resetPassword = (email) => {
    this.resetingPassword = true;
    return auth
      .doPasswordReset(email)
      .then(
        action(() => {
          this.resetingPassword = false;
        })
      )
      .catch(
        action((err) => {
          this.resetingPassword = false;
        })
      );
  };

  confirmResetPassword = async (code, email, password) => {
    this.resetingPassword = true;

    await auth.doConfirmPasswordReset(code, password);

    return auth.doSignInWithEmailAndPassword(email, password).then(
      action(() => {
        this.resetingPassword = false;
      })
    );
  };

  set = (uid) => {
    if (!uid) {
      this.user = {};
      return;
    }

    this.loading = true;

    db.users.get(uid).onSnapshot(
      action((doc) => {
        this.user = doc.exists ? doc.data() : {};
        this.setAnnualBalance();

        db.users.processes.getKyc(uid).onSnapshot(
          action((snapshot) => {
            this.loading = false;

            if (!snapshot.exists) return;

            this.kyc = firebaseHelper.docToObject(snapshot);
          })
        );
      })
    );
  };

  setAnnualBalance = () => {
    const { uid } = this.rootStore.sessionStore.authUser;

    db.users.annualBalance
      .get(uid, "2019")
      .then((snap) => snap.data())
      .then(
        action((lastYear) => {
          this.annualBalance = {
            ...this.annualBalance,
            lastYear,
          };
        })
      );

    db.users.annualBalance
      .get(uid, "2018")
      .then((snap) => snap.data())
      .then(
        action((previousYear = {}) => {
          this.annualBalance = {
            ...this.annualBalance,
            previousYear,
          };
        })
      );
  };

  verifyPasswordResetCode = (code) => auth.doVerifyPasswordResetCode(code);
}

decorate(UserStore, {
  annualBalance: observable,
  changingEmail: observable,
  creating: observable,
  loading: observable,
  kyc: observable,
  user: observable,
  refer: observable,
  hasLastYearBalance: computed,
  firstName: computed,
  kycApproved: computed,
  kycChecking: computed,
  kycCompleted: computed,
  kycDenied: computed,
  kycInformed: computed,
  set: action,
  create: action,
  completeRegistration: action,
  confirmResetPassword: action,
  get: action,
  resetPassword: action,
  createRefer: action,
  processesRefer: action,
  verifyPasswordResetCode: action,
  setAnnualBalance: action,
});

export default UserStore;
