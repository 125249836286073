import React from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { compose } from 'recompose'
import { when } from 'mobx'
import { firebase } from '../firebase'
import { routes } from '../constants'

const withoutAuthentication = () => Component => {
  class WithoutAuthentication extends React.Component {

    UNSAFE_componentWillMount() {
      const { userStore, sessionStore, history } = this.props

      firebase.auth.onAuthStateChanged(authUser => {
        when(
          () => !userStore.creating || sessionStore.signingIn,
          () => {
            const userIsAuthenticated = !!authUser
            userIsAuthenticated && history.push(routes.MY_INVESTMENTS)
          }
        )
      })
    }

    render() {
      return <Component />
    }
  }

  return compose(
    withRouter,
    inject('userStore', 'sessionStore'),
    observer
  )(WithoutAuthentication)
}

export default withoutAuthentication
