import React from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { compose } from 'recompose'
import { when } from 'mobx'

import { firebase } from '../firebase'
import { routes } from '../constants'

const withAuthorization = (authCondition = auth => !!auth, redirectTo = routes.SIGN_IN) => Component => {
  class WithAuthorization extends React.Component {

    UNSAFE_componentWillMount() {
      const { userStore, sessionStore, history, location } = this.props

      firebase.auth.onAuthStateChanged(authUser => {
        const loading = userStore.creating || sessionStore.signingIn

        when(
          () => !loading,
          () => {
            const requireAuth = ![routes.SIGN_IN, routes.SIGN_UP, routes.PASSWORD_RESET].some(route => location.pathname.startsWith(route))
            const isAuthorized = !!authUser && authCondition(sessionStore.authUser)

            requireAuth && !isAuthorized && history.push(redirectTo)
          }
        )
      })
    }

    render() {
      return authCondition(this.props.sessionStore.authUser) ? <Component /> : null
    }
  }

  return compose(
    withRouter,
    inject('sessionStore', 'userStore'),
    observer
  )(WithAuthorization)
}

export default withAuthorization
