import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { inject, observer } from 'mobx-react'
import PasswordForgetForm from '../Forget/Form'
import { UIModalElem as UIModal } from '../../UI'
import SignUpLink from '../../SignUp/Link';

const Message = styled.p`
  text-align: center;
  max-width: 420px;
  margin-top: 25px;
`

const PasswordResetLinkExpired = () =>
  <React.Fragment>
    <UIModal.Title>Ops! Esse link expirou.</UIModal.Title>
    <Message>Informe seu email cadastrado para receber um novo link e redefinir sua senha.</Message>
    <PasswordForgetForm buttonText="Solicitar novo link" />
    <UIModal.Footer>
      Não possui uma conta?&nbsp;<SignUpLink>Cadastre-se aqui</SignUpLink>
    </UIModal.Footer>
  </React.Fragment>

export default compose(
  withRouter,
  inject('modalStore'),
  observer
)(PasswordResetLinkExpired)
