export const ACCOUNT = "/account";
export const HELP = "/help";
export const HOME = "/home";
export const LANDING = "/";
export const MY_INVESTMENTS = "/my-investments";
export const PASSWORD_FORGET = "/pw-forget";
export const PASSWORD_RESET = "/password-reset";
export const REFER = "/r";
export const REFER_CAMPAIGN = "/refer-campaign";
export const SIGN_IN = "/signin";
export const SIGN_UP = "/signup";
export const STRATEGIES = "/strategies";
export const STRATEGY = "/strategy";
export const TRANSACTIONS = "/transactions";
export const REPORT_IR = "/ir";
