import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import { UIModalElem, UIButton } from '../UI'

import AlertBellIcon from '../../static/images/icons/colored-bell-alert.svg'
import { compose } from 'recompose'
import { inject } from 'mobx-react'
import { routes } from '../../constants'

const P = styled.p`
  line-height: 1.5;
`

const goToAccount = (history, { doClose }) => () => {
  history.push(routes.ACCOUNT)
  doClose()
}

const ModalRequireRegistration = ({ history, modalStore }) =>
  <React.Fragment>
    <UIModalElem.Icon src={AlertBellIcon} alt="Ícone de alerta" />
    <UIModalElem.Title direction="center">Atualização necessária</UIModalElem.Title>
    <P>Em 1º de Agosto de 2019 entrou em vigor a <strong>Instrução Normativa 1888/2019 da Receita Federal</strong>. A IN 1888/2019 surge com a intenção de obter mais informações sobre o mercado de criptomoedas e instituir a obrigatoriedade na prestação de informações para ter um controle maior no que diz respeito à receita das pessoas, sejam elas físicas ou jurídicas.</P>
    <P>Na prática, serão informadas todas as operações de depósito, compra, venda e saque de reais e/ou criptomoedas realizadas dentro da Investtor.</P>
    <P>Para isso é obrigatório que seus dados cadastrais como: nome completo, CPF, data de nascimento, telefone celular e endereço residencial estejam devidamente atualizados em nosso sistema.</P>
    <P style={{ marginBottom: '20px' }}><strong>Vamos atualizar seus dados?</strong></P>
    <UIButton primary large centered onClick={goToAccount(history, modalStore)}>Atualizar meus dados</UIButton>
  </React.Fragment>

export default compose(
  withRouter,
  inject('modalStore')
)(ModalRequireRegistration)
