import React from 'react'
import { compose } from 'recompose'
import { inject, observer } from 'mobx-react'
import { Form, Field } from 'react-final-form'
import { isMobile } from 'react-device-detect'

import { UIForm, UIGrid, UIButton, UILoading } from '../../UI'
import { composeValidators, Required, FullName } from '../../UI/FormValidate'

const onSubmit = userStore => values =>
  userStore.completeRegistration(values)

const AccountFormPersonal = ({ userStore }) => {
  const { user } = userStore
  const readOnly = prop => user[prop] !== undefined

  return (
    <Form
      onSubmit={onSubmit(userStore)}
      initialValues={{
        name: user.name,
        nickname: user.nickname,
        cpf: user.cpf,
        birthday: user.birthday,
      }}
      render={({ handleSubmit, pristine, invalid }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="name"
            validate={
              composeValidators(
                Required(),
                FullName(),
              )
            }
            render={({ input, meta }) => (
              <UIForm.Input
                {...{ ...input, ...meta }}
                readOnly={readOnly('name')}
                label="Nome completo"
                autocomplete="name" />
            )} />
          <Field
            name="nickname"
            render={({ input, meta }) => (
              <UIForm.Input
                {...{ ...input, ...meta }}
                label="Como devo te chamar?"
                autocomplete="nickname" />
            )} />
          <UIGrid.Row>
            <UIGrid.Column md={6}>
              <Field
                name="cpf"
                validate={
                  composeValidators(
                    Required(),
                  )
                }
                render={({ input, meta }) => (
                  <UIForm.Input
                    {...{ ...input, ...meta }}
                    readOnly={readOnly('cpf')}
                    label="CPF" />
                )} />
            </UIGrid.Column>
            <UIGrid.Column md={6}>
              <Field
                name="birthday"
                validate={
                  composeValidators(
                    Required(),
                  )
                }
                render={({ input, meta }) => (
                  <UIForm.Input
                    {...{ ...input, ...meta }}
                    readOnly={readOnly('birthday')}
                    label="Data de nascimento" />
                )} />
            </UIGrid.Column>
          </UIGrid.Row>
          <Field
            name="politicallyExposed"
            type="checkbox"
            render={({ input, meta }) => (
              <UIForm.Checkbox
                {...input}
                {...meta}
                label={(isMobile ? 'P' : 'Pessoa p') + 'oliticamente exposta'}
                tooltip="Pessoa politicamente exposta é aquela que desempenha ou tenha desempenhado, nos últimos 5 (cinco) anos, cargos, empregos ou funções públicas relevantes, no Brasil ou em outros países, territórios e dependências estrangeiros, assim como seus representantes, familiares e outras pessoas de seu relacionamento próximo.">
              </UIForm.Checkbox>
            )} />
          <UIButton type="submit" large primary fullWidth={isMobile} width={215} disabled={pristine || invalid || userStore.creating}>
            <UILoading.Button loading={userStore.creating}>
              Salvar
            </UILoading.Button>
          </UIButton>
        </form>
      )} />
  )
}

export default compose(
  inject('userStore'),
  observer,
)(AccountFormPersonal)
