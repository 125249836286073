import React from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import { compose } from 'recompose'
import * as Modal from '../../Modal'
import { UITable, UIFilter, UIIcon } from '../../UI'
import { TransactionHelper } from '../../../helpers'

const Item = styled(UITable.Row)`
  cursor: pointer;
`

const BorderedColumn = styled(UITable.Column).attrs(() => ({
  bordered: true,
}))``

const modalToShow = (type, transaction) =>
  type.deposit || type.bonus
    ? <Modal.Transaction.Deposit {...{ transaction }} />
    : <Modal.Transaction.Withdraw {...{ transaction }} />

const TransactionItem = ({ transaction, modalStore }) => {
  const type = TransactionHelper.type(transaction)
  const onClick = () => modalStore.doOpen(modalToShow(type, transaction))
  const transactionDate = (transaction.pending ? transaction.createdAt : transaction.closedAt).toDate()

  return (
    <Item {...{ onClick }}>
      <BorderedColumn hiddenXs>
        <UIFilter.DateTime
          value={transactionDate}
          format="DD/MM/YY - HH:mm" />
      </BorderedColumn>
      <BorderedColumn firstXs>
        <UIIcon.Transaction {...{ type }} />
      </BorderedColumn>
      <BorderedColumn>
        {
          (type.deposit || type.bonus)
            ? <UIFilter.Currency value={transaction.amount} />
            : <UIFilter.Currency value={transaction.values.amount} />
        }
      </BorderedColumn>
      <BorderedColumn rightArrow={true}></BorderedColumn>
    </Item>
  )
}

export default compose(
  inject('modalStore'),
  observer
)(TransactionItem)
