import styled from 'styled-components';
import { device } from '../../../constants'

const UICard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  border-radius: 4px;
  flex: 1 1 auto;
  padding: 20px;
  height: 100%;

  margin-bottom: 20px;

  @media ${device.laptop} {
    margin-right: 20px;
  }
`

export default UICard
