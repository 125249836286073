const isFiat = symbol => symbol === 'BRL' || symbol === 'USD'

const UIFilterCurrency = ({ value, absolute, decimals = '2', symbol = 'BRL', currencyDisplay = 'symbol' }) => {
  const _value = absolute ? Math.abs(value) : value

  return isFiat(symbol)
    ? _value.toLocaleString('pt-br', { style: 'currency', currency: symbol, decimals, currencyDisplay })
    : `${_value.toFixed(decimals)} ${symbol}`
}

export default UIFilterCurrency
