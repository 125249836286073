import { functions } from '../firebase'

const callCoinPriceBitcoin = functions.httpsCallable('callCoinPriceBitcoin')
const callInvestCancelDeposit = functions.httpsCallable('callInvestCancelDeposit')
const callInvestDeposit = functions.httpsCallable('callInvestDeposit')
const callInvestDistribution = functions.httpsCallable('callInvestDistribution')
const callInvestInformDeposit = functions.httpsCallable('callInvestInformDeposit')
const callInvestWithdraw = functions.httpsCallable('callInvestWithdraw')
const callStrategiesPerformance = functions.httpsCallable('callStrategiesPerformance')
const callUserExistsOnAc = functions.httpsCallable('callUserExistsOnAc')
const callUserInvestments = functions.httpsCallable('callUserInvestments')
const callUserNotifyRegistration = functions.httpsCallable('callUserNotifyRegistration')
const callUserRefer = functions.httpsCallable('callUserRefer')
const callUserReferChild = functions.httpsCallable('callUserReferChild')
const callUserSetPersonalData = functions.httpsCallable('callUserSetPersonalData')
const callUserStoreSession = functions.httpsCallable('callUserStoreSession')
const callUserTrackEvent = functions.httpsCallable('callUserTrackEvent')

export {
  callCoinPriceBitcoin,
  callInvestCancelDeposit,
  callInvestDeposit,
  callInvestDistribution,
  callInvestInformDeposit,
  callInvestWithdraw,
  callStrategiesPerformance,
  callUserExistsOnAc,
  callUserInvestments,
  callUserNotifyRegistration,
  callUserRefer,
  callUserReferChild,
  callUserSetPersonalData,
  callUserStoreSession,
  callUserTrackEvent,
}
