import React from 'react'
import Pending from './Pending'
import Realized from './Realized'
import { observer } from 'mobx-react';

const ModalWithdraw = ({ transaction }) =>
  transaction.pending
    ? <Pending {...{ transaction }} />
    : <Realized {...{ transaction }} />

export default observer(ModalWithdraw)
