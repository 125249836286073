import Actions from './Actions'
import Button from './Button'
import Info from './Info'
import Modal from './Modal'
import {
  UIModalIcon as Icon,
  UIModalFooter as Footer,
  UIModalTitle as Title
} from './Elements'

export default Modal

export {
  Actions,
  Button,
  Icon,
  Info,
  Footer,
  Title,
}
