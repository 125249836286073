import styled from 'styled-components'

const Landing = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    color: #fff;
    justify-content: center;
    z-index: 2;
    background: #672683 url(${process.env.PUBLIC_URL}/investtor-96x96.png) no-repeat center;
`

export default Landing;
