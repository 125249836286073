import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { device } from '../../../constants'

const UIButton = styled.button.attrs(({ type }) => ({
  type: !!type ? type : 'button',
}))`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  text-transform: ${({ lower }) => lower ? 'none' : 'uppercase'};
  font-size: 0.9375em;
  font-weight: bold;
  border: none;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;

  :disabled {
    opacity: 0.5;
    cursor: auto;
  }

  ${({ large, medium }) =>
    (large && `height: 54px;`) ||
    (medium && `
      height: 32px;
      font-size: 0.75em;
    `)
  };

  ${({ primary, secondary, text, outline }) =>
    (primary && `
      background-color: ${outline ? 'var(--white)' : 'var(--turquoise-blue)'};
      color: ${text || outline ? 'var(--turquoise-blue)' : 'var(--white)'};
      ${outline && `
        border: 1px solid var(--turquoise-blue);
      `}
    `) ||
    (secondary && `
      background-color: ${outline ? 'var(--white)' : 'var(--maize)'};
      color: ${outline ? 'var(--maize)' : 'var(--white)'};
      ${outline && `
        border: 1px solid var(--maize);
      `}
    `) || `
      background-color: ${outline ? 'var(--white)' : 'var(--grey100)'};
      color: var(--grey200);
      ${outline && `
        border: 1px solid var(--grey200);
      `}
    `
  };

  ${({ centered, large }) => centered && (large
    ? `
      margin: auto;
      width: 100%;

      @media ${device.mobileM} {
        width: 300px;
      }
    ` : `
      margin: auto;
    `
  )};

  ${({ fullWidth, width }) =>
    (fullWidth && `width: 100%;`) ||
    (width && `width: ${width}px`)
  };

  ${({ text }) => text && `
    display: inline-block;
    background-color: transparent;
  `};
`

const UIButtonLink = ({ to, children, ...props }) => {
  const Wrapper = styled(UIButton)`
    text-decoration: none;
  `;

  return <Wrapper {...props} as={Link} to={to}>{children}</Wrapper>
}

const TooltipSpan = styled.span`
  ${({ spanStyle }) => spanStyle};
`

// TODO: react-tooltip Workaround em componentes com estado disabled
// https://github.com/wwayne/react-tooltip/issues/304
// https://github.com/SeleniumHQ/selenium-ide/issues/572
// https://github.com/SeleniumHQ/selenium-ide/pull/608
const UIButtonTooltip = ({ disabled, tooltip, spanStyle, children, ...props }) =>
  <React.Fragment>
    <TooltipSpan {...{ spanStyle }} data-tip={tooltip} data-for="UIButtonTooltip">
      <UIButton {...{ disabled, ...props }}>
        {children}
      </UIButton>
    </TooltipSpan>
    {
      disabled && (
        <ReactTooltip
          id="UIButtonTooltip"
          place="top"
          effect="solid"
          className="tooltip tooltip--help"
          getContent={datatip => datatip} />
      )
    }
  </React.Fragment>

export default UIButton

export {
  UIButtonLink,
  UIButtonTooltip,
}
