import React from 'react'
import * as Sentry from '@sentry/browser'
import { render, createPortal } from 'react-dom'
import { Provider } from 'mobx-react'
import { EnviromentHelper } from './helpers'
import { ToastContainer } from 'react-toastify'

import 'normalize.css'
import './styles/reactToastfy.css'

import ScreensRoot from './screens/Root'
import store from './stores'
import registerServiceWorker from './registerServiceWorker'

Sentry.init({
  dsn: 'https://a3128a3fa30d49b6a4f5e2f5540f5ac4@sentry.io/1404891',
  environment: EnviromentHelper.name
})

const ToastPortal = ({ children }) => createPortal(children, document.getElementById('toast'))

render(
  <React.Fragment>
    <ToastPortal>
      <ToastContainer />
    </ToastPortal>
    <Provider {...store}>
      <ScreensRoot />
    </Provider>
  </React.Fragment>,
  document.getElementById('root'))
registerServiceWorker()
