import {action, computed, decorate, observable} from "mobx";
import {db, firebaseHelper} from "../firebase";

class UserRefersStore {
    fetching = true;
    refers = [];

    get value() {
        return this.refers
          .reduce((total, refer) => {
            if (!refer.bonus) return total

            return total + (refer.value ? refer.value.BRL : 10)
          }, 0)
    }

    get count() {
        return this.refers.length;
    }

    getAll(uid) {
        db.users.refers.getAll(uid)
            .onSnapshot(action(snapshot => {
                this.refers = firebaseHelper.docsToArray(snapshot);
                this.fetching = false
            }));
    }
}

decorate(UserRefersStore, {
    fetching: observable,
    refers: observable,
    value: computed,
    count: computed,
    getAll: action,
});

export default UserRefersStore;
