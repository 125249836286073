import { functions } from '../firebase'
import { action, decorate, observable, computed } from 'mobx'

class InvestStore {
  calculating = true
  creatingTransaction = false
  value = 0
  coins = []

  constructor(strategyId, initialValue) {
    this.initialValue = parseFloat(initialValue)
    this.value = this.initialValue

    functions.investDistribution(strategyId, initialValue)
      .then(res => res.data)
      .then(action(res => {
        this.coins = res
        this.calculating = false
      }))
  }

  makeDeposit = (investment, coins, amount) => {
    this.creatingTransaction = true
    return functions.investDeposit(investment, coins, amount)
      .then(res => res.data)
      .then(action(transaction => {
        this.creatingTransaction = false
        return transaction
      }))
  }
  setValue = value => this.value = parseFloat(value)

  get coefficient() {
    return this.value / this.initialValue
  }

  get simulatedValues() {
    return this.coefficient === 1
      ? this.coins
      : this.coins.map(coin => ({
        ...coin,
        amount: parseFloat((coin.amount * this.coefficient).toFixed(coin.decimals))
      }))
  }
}

decorate(InvestStore, {
  calculating: observable,
  creatingTransaction: observable,
  value: observable,
  coins: observable,
  coefficient: computed,
  simulatedValues: computed,
  setValue: action,
  makeDeposit: action,
})

export default InvestStore
