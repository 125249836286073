import * as func from './Functions'

const coinPriceBitcoin = ({ side }) => func.callCoinPriceBitcoin({ side })
const investCancelDeposit = transaction => func.callInvestCancelDeposit(transaction)
const investDeposit = (investment, coins, amount) => func.callInvestDeposit({ investment, coins, amount })
const investDistribution = (strategyId, value) => func.callInvestDistribution({ strategyId, value })
const investInformDeposit = transaction => func.callInvestInformDeposit({ transaction })
const investWithdraw = transaction => func.callInvestWithdraw(transaction)
const strategyPerformance = strategy => func.callStrategiesPerformance({ strategy })
const userExistsOnAc = () => func.callUserExistsOnAc()
const userInvestments = investments => func.callUserInvestments({ investments })
const userNotifyRegistration = ({ email, isAndroidUser }) => func.callUserNotifyRegistration({ email, isAndroidUser })
const userRefer = () => func.callUserRefer();
const userReferChild = (refer, email) => func.callUserReferChild({ refer, email })
const userSetPersonalData = user => func.callUserSetPersonalData(user)
const userStoreSession = () => func.callUserStoreSession()
const userTrackEvent = (eventName, eventData) => func.callUserTrackEvent({ eventName, eventData })

export {
  coinPriceBitcoin,
  investCancelDeposit,
  investDeposit,
  investDistribution,
  investInformDeposit,
  investWithdraw,
  strategyPerformance,
  userExistsOnAc,
  userInvestments,
  userNotifyRegistration,
  userRefer,
  userReferChild,
  userSetPersonalData,
  userStoreSession,
  userTrackEvent,
}
