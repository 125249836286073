import React from "react";

import withAuthorization from "../components/withAuthorization";

import ReportIRPage from "../components/ReportIR/Page";

const ScreensReportIR = () => <ReportIRPage></ReportIRPage>;

const userIsAuthenticated = auth => !!auth;

export default withAuthorization(userIsAuthenticated)(ScreensReportIR);
