import React from "react";
import { compose } from "recompose";
import { observer, inject } from "mobx-react";
import { withRouter } from "react-router-dom";

import { routes } from "../../../constants";
import * as Modal from "../../Modal";
import UIButtonDropdown from "../Button/Dropdown";
import UIButtonIconOnly from "../Button/IconOnly";

import PreferencesIcon from "../../../static/images/navbar/preferences-grey.svg";
import AccountIcon from "../../../static/images/navbar/account-blue.svg";
import DownloadBlue from "../../../static/images/navbar/download-blue.svg";
import PasswordIcon from "../../../static/images/navbar/lock-blue.svg";

const options = (modalStore, userStore, history) => [
  {
    text: "Dados pessoais",
    icon: AccountIcon,
    onClick: () => history.push(`${routes.ACCOUNT}`)
  },
  {
    text: "Imposto de renda 2020",
    icon: DownloadBlue,
    onClick: () => history.push(`${routes.REPORT_IR}`),
    hide: !userStore.hasLastYearBalance
  },
  // {s
  //   text: 'Trocar email',
  //   icon: EmailIcon,
  //   onClick: () => modalStore.doOpen(<Modal.ChangeEmail />)
  // },
  {
    text: "Trocar senha",
    icon: PasswordIcon,
    onClick: () => modalStore.doOpen(<Modal.ChangePassword />)
  }
];

const UINavbarPreferencesButton = ({ modalStore, userStore, history }) => (
  <UIButtonDropdown
    Button={props => (
      <UIButtonIconOnly
        icon={PreferencesIcon}
        aria-label="Configurações"
        alt="Configurações"
        {...props}
      ></UIButtonIconOnly>
    )}
    options={options(modalStore, userStore, history)}
  ></UIButtonDropdown>
);

export default compose(
  withRouter,
  inject("modalStore", "userStore"),
  observer
)(UINavbarPreferencesButton);
