import React, { useState, useEffect } from 'react'
import queryString from 'querystring'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { inject, observer } from 'mobx-react'
import { Form, Field } from 'react-final-form'
import { device } from '../../../constants'
import { UIForm, UIButton, UIFormValidate, UILoading } from '../../UI'
import LinkExpiredModal from './LinkExpired'


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 250px;

  @media ${device.laptop} {
    width: 70%;
    max-width: 500px;
  }
`
const Title = styled.h1`
  margin-top: 0;
  margin-bottom: 70px;
`

const ResetPasswordButton = styled(UIButton).attrs(() => ({
  large: true,
  primary: true,
  fullWidth: true,
  type: "submit",
}))`
  margin-top: 40px;
`

const onSubmit = (userStore, oobCode, email) => ({ password }) =>
  userStore.confirmResetPassword(oobCode, email, password)

const PasswordResetForm = ({ modalStore, userStore, location }) => {
  const qsParams = queryString.parse(location.search.slice(1))
  const oobCode = qsParams.oobCode

  const [email, setEmail] = useState(null)

  useEffect(() => {
    userStore.verifyPasswordResetCode(oobCode)
      .then(email => {
        setEmail(email)
      })
      .catch(() => {
        const { authUser } = userStore.sessionStore
        const modalProps = {
          centered: true,
          medium: true,
          backdrop: false,
          showClose: false,
        }
        !authUser && modalStore.doOpen(<LinkExpiredModal />, modalProps)
      })
  })

  return (
    <Wrapper>
      <Title>Cadastre sua nova senha de acesso</Title>
      <Form
        onSubmit={onSubmit(userStore, oobCode, email)}
        validate={values => {
          const errors = {}

          if (values.password !== values.confirm) {
            errors.confirm = 'As senhas não conferem'
          }

          return errors
        }}
        render={({ handleSubmit, pristine, invalid }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="password"
              validate={
                UIFormValidate.composeValidators(
                  UIFormValidate.Required(),
                  UIFormValidate.MinLength(8, 'Sua senha deve ter ao menos 6 caracteres'),
                )}
              render={({ input, meta }) => (
                <UIForm.Input {...{ ...input, ...meta }} type="password" label="Sua nova senha" />
              )}>
            </Field>
            <Field
              name="confirm"
              validate={
                UIFormValidate.composeValidators(
                  UIFormValidate.Required(),
                )}
              render={({ input, meta }) => (
                <UIForm.Input {...{ ...input, ...meta }} type="password" label="Confirme sua nova senha" />
              )}>
            </Field>
            <ResetPasswordButton disabled={pristine || invalid || userStore.resetingPassword}>
              <UILoading.Button loading={userStore.resetingPassword}>
                Redefinir e entrar
            </UILoading.Button>
            </ResetPasswordButton>
          </form>
        )}>
      </Form>
    </Wrapper>
  )
}

export default compose(
  withRouter,
  inject('userStore', 'modalStore'),
  observer,
)(PasswordResetForm)
