import React from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import TooltipIcon from '../../../static/images/icons/grey-tooltip.svg'
import { observer } from 'mobx-react'


const Tooltip = styled.img.attrs(({ alt = 'Mais detalhes Tooltip' }) => ({
  alt: alt,
  src: TooltipIcon,
}))`
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`

const UITooltipHelp = ({ children, place = 'top', ...props }) => {
  const id = `tip_${new Date().getTime()}`

  return (
    <React.Fragment>
      <Tooltip data-tip data-for={id} />
      <ReactTooltip
        {...{ id, place }}
        effect="solid"
        className="tooltip tooltip--help">
        {children}
      </ReactTooltip>
    </React.Fragment>
  )
}

export default observer(UITooltipHelp)
