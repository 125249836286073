import React from 'react'
import styled from 'styled-components'

const Loader = styled.span`
  position: relative;
  display: block;
  margin: 0 auto;
  transform: translateZ(0);
  animation: loading-animation 1.1s infinite linear;
  border-radius: 50%;
  border: 4px solid var(--white);
  border-left-color: transparent;
  height: 20px;
  width: 20px;


  @keyframes loading-animation
  {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`

const UILoadingButton = ({ loading, children }) =>
  <React.Fragment>
    {loading ? <Loader /> : children}
  </React.Fragment>

export default UILoadingButton
