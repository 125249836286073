import React from 'react'
import styled from 'styled-components'
import { compose } from 'recompose'
import { inject, observer } from 'mobx-react'
import { UIModalElem as UIModal, UIForm, UITable, UIButton, UILoading, UITooltip } from '../UI'
import * as Modal from '.'
import InvestStore from '../../stores/InvestStore'

const H3 = styled.h3`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 50px;
`

const Button = styled(UIButton)`
  margin-top: 40px;
`

class ModalInvest extends React.Component {

  constructor(props) {
    super(props)

    const { strategyId, strategiesStore } = props

    this.strategy = strategiesStore.find(strategyId).store.details

    this.investStore = new InvestStore(this.strategy.id, this.strategy.minValue)
  }

  showKycDenied = () => {
    const { modalStore, userStore } = this.props

    modalStore.showInfo({
      icon: require('../../static/images/icons/colored-hourglass.svg'),
      title: 'Seu cadastro ainda não foi aprovado.',
      message: userStore.kyc.denied,
    }, {
        medium: true,
      })
  }

  handleInvest = () => {
    const { modalStore, userStore } = this.props
    const { id, title, quotation } = this.strategy
    const { simulatedValues, value, makeDeposit } = this.investStore

    const createDeposit = () =>
      makeDeposit({
        strategy: {
          id,
          title
        },
        quotation: quotation,
      }, simulatedValues, value).then(transaction => {
        modalStore.doOpen(<Modal.Transaction.Deposit {...{ transaction }} />)
      })

    const { kycApproved, kycChecking, kycDenied } = userStore

    kycDenied
      ? this.showKycDenied()
      : kycApproved || kycChecking
        ? createDeposit()
        : modalStore.doOpen(<Modal.CompleteRegistration onSuccess={createDeposit} />)
  }

  render() {
    const { title, minValue } = this.strategy
    const { calculating, simulatedValues: coins, setValue, creatingTransaction, value } = this.investStore

    const allowInvest = false //!calculating && !creatingTransaction && (value >= minValue)
    const investValueProps = {
      currentValue: value,
      onChange: setValue,
      minValue,
    }

    return (
      <React.Fragment>
        <UIModal.Title>{title}</UIModal.Title>
        <UIForm.InvestValue vertical {...investValueProps} />
        <H3>
          Distribuição
          <UITooltip.Help>
            A compra será realizada após a confirmação da transferência. Os valores são aproximados e podem variam de acordo com o momento da compra.
          </UITooltip.Help>
        </H3>
        <UILoading.Inline loading={calculating} message="buscando as melhores ofertas...">
          <UITable.Distribution allocation amount {...{ coins }} />
        </UILoading.Inline>
        <Button centered large primary disabled={!allowInvest}>
          <UILoading.Button loading={creatingTransaction}>
            Comprar
          </UILoading.Button>
        </Button>
      </React.Fragment>
    )
  }
}

export default compose(
  inject('modalStore', 'userStore', 'strategiesStore'),
  observer
)(ModalInvest)
