const bankIcon = name => require(`../static/images/bank/${name}.svg`)

const withdrawFee = 0
const standards = [
  {
    code: '001',
    name: 'Banco do Brasil (001)',
    icon: bankIcon('bancodobrasil'),
    noFee: true,
  },
  {
    code: '033',
    name: 'Banco Santander (033)',
    icon: bankIcon('santander'),
  },
  {
    code: '077',
    name: 'Banco Inter (077)',
    icon: bankIcon('inter'),
  },
  {
    code: '104',
    name: 'Caixa Econômica Federal (104)',
    icon: bankIcon('caixa'),
  },
  {
    code: '237',
    name: 'Banco Bradesco (237)',
    icon: bankIcon('bradesco'),
  },
  {
    code: '260',
    name: 'NuBank (260)',
    icon: bankIcon('nubank'),
  },
  {
    code: '341',
    name: 'Itaú Unibanco (341)',
    icon: bankIcon('itau'),
  },
  {
    code: 'outro',
    name: 'Selecione...',
    icon: bankIcon('outro'),
  },
]
const all = [
  {
    code: '101',
    name: 'BEB (101)'
  },
  {
    code: '102',
    name: 'BEP (102)'
  },
  {
    code: '104',
    name: 'Caixa Economica Federal S/A (104)'
  },
  {
    code: '105',
    name: 'Suritomo Brasileiro S/A (105)'
  },
  {
    code: '107',
    name: 'Mitsubishi Brasileiro S/A (107)'
  },
  {
    code: '111',
    name: 'BEG (111)'
  },
  {
    code: '112',
    name: 'Paraiban (112)'
  },
  {
    code: '113',
    name: 'BEM (113)'
  },
  {
    code: '117',
    name: 'Mult Banco S/A (117)'
  },
  {
    code: '118',
    name: 'BEPI (118)'
  },
  {
    code: '119',
    name: 'Battistella S/A (119)'
  },
  {
    code: '120',
    name: 'Augusgo Indal. Coml. S/A (120)'
  },
  {
    code: '121',
    name: 'Banco Agiplan S.A. (121)'
  },
  {
    code: '122',
    name: 'Bradesco BERJ (122)'
  },
  {
    code: '123',
    name: 'BESE (123)'
  },
  {
    code: '124',
    name: 'BEAM (124)'
  },
  {
    code: '125',
    name: 'Brasil Plural (125)'
  },
  {
    code: '126',
    name: 'Exprinter Losan S/A (126)'
  },
  {
    code: '127',
    name: 'Equatorial S/A (127)'
  },
  {
    code: '129',
    name: 'BEMT (129)'
  },
  {
    code: '130',
    name: 'BEAC (130)'
  },
  {
    code: '132',
    name: 'MAXI (132)'
  },
  {
    code: '136',
    name: 'CC Unicred do Brasil (136)'
  },
  {
    code: '138',
    name: 'Ficrisa Axelrud S/A (138)'
  },
  {
    code: '184',
    name: 'Bba Creditanstal S/A (184)'
  },
  {
    code: '204',
    name: 'Srl S/A (204)'
  },
  {
    code: '208',
    name: 'Pactual S/A (208)'
  },
  {
    code: '212',
    name: 'Banco Original S/A (212)'
  },
  {
    code: '213',
    name: 'Arbi S/A (213)'
  },
  {
    code: '214',
    name: 'Dibens S/A (214)'
  },
  {
    code: '217',
    name: 'Agroinvest S/A (217)'
  },
  {
    code: '218',
    name: 'Banco Bonsucesso S/A (218)'
  },
  {
    code: '222',
    name: 'Banco Calyon S/A (222)'
  },
  {
    code: '224',
    name: 'Fibra S/A (224)'
  },
  {
    code: '230',
    name: 'Bandeirantes S/A (230)'
  },
  {
    code: '233',
    name: 'Mappin S/A (233)'
  },
  {
    code: '237',
    name: 'Bradesco S/A (237)'
  },
  {
    code: '241',
    name: 'Classico S/A (241)'
  },
  {
    code: '243',
    name: 'Stock S/A (243)'
  },
  {
    code: '246',
    name: 'Abc Roma S/A (246)'
  },
  {
    code: '248',
    name: 'Banco Boavista Interatlântico S/A (248)'
  },
  {
    code: '249',
    name: 'Investcred S/A (249)'
  },
  {
    code: '250',
    name: 'Schahin Cury S/A (250)'
  },
  {
    code: '254',
    name: 'Parana Banco S/A (254)'
  },
  {
    code: '260',
    name: 'NU PAGAMENTOS S.A. (260)'
  },
  {
    code: '263',
    name: 'Cacique S/A (263)'
  },
  {
    code: '265',
    name: 'Fator S/A (265)'
  },
  {
    code: '266',
    name: 'Cedula S/A (266)'
  },
  {
    code: '300',
    name: 'Nacion Argentina S/A (300)'
  },
  {
    code: '318',
    name: 'Bmg S/A (318)'
  },
  {
    code: '320',
    name: 'Indl e Comercial S/A (320)'
  },
  {
    code: '341',
    name: 'Banco Itau S/A (341)'
  },
  {
    code: '366',
    name: 'Sogeral S/A (366)'
  },
  {
    code: '370',
    name: 'Europru P/AM. Latina (370)'
  },
  {
    code: '376',
    name: 'Chase Manhattan S/A (376)'
  },
  {
    code: '389',
    name: 'Mercantil do Brasil S/A (389)'
  },
  {
    code: '394',
    name: 'Bmc S/A (394)'
  },
  {
    code: '399',
    name: 'Banco HSBC S/A (399)'
  },
  {
    code: '412',
    name: 'Nacional Da Bahia S/A (412)'
  },
  {
    code: '422',
    name: 'Safra S/A (422)'
  },
  {
    code: '456',
    name: 'Tokio S/A (456)'
  },
  {
    code: '464',
    name: 'Sumitomo S/A (464)'
  },
  {
    code: '473',
    name: 'Financial Portugues S/A (473)'
  },
  {
    code: '477',
    name: 'Citibank N.A. (477)'
  },
  {
    code: '479',
    name: 'ITAU BANK PERSONALITTE (479)'
  },
  {
    code: '487',
    name: 'Deutsche Bank S/A (487)'
  },
  {
    code: '488',
    name: 'Morgan Guaranty Trust Company of NY (488)'
  },
  {
    code: '492',
    name: 'Internat. Nederlanden Bank NV (492)'
  },
  {
    code: '494',
    name: 'Republica Oriental Del Uruguay (494)'
  },
  {
    code: '495',
    name: 'Provincia de Buenos Aires (495)'
  },
  {
    code: '505',
    name: 'Garantia S/A (505)'
  },
  {
    code: '600',
    name: 'Luso Brasileiro S/A (600)'
  },
  {
    code: '604',
    name: 'Indl do Brasil S/A (604)'
  },
  {
    code: '610',
    name: 'V R S/A (610)'
  },
  {
    code: '611',
    name: 'Paulista S/A (611)'
  },
  {
    code: '612',
    name: 'Guanabara S/A (612)'
  },
  {
    code: '613',
    name: 'Pecunia S/A (613)'
  },
  {
    code: '623',
    name: 'Panamericano S/A (623)'
  },
  {
    code: '626',
    name: 'Ficsa S/A (626)'
  },
  {
    code: '630',
    name: 'Intercap S/A (630)'
  },
  {
    code: '633',
    name: 'Rendimento S/A (633)'
  },
  {
    code: '634',
    name: 'Triangulo S/A (634)'
  },
  {
    code: '637',
    name: 'Sofisa S/A (637)'
  },
  {
    code: '641',
    name: 'Banco Alvorada S.A. (641)'
  },
  {
    code: '643',
    name: 'Banco Pine S.A. (643)'
  },
  {
    code: '652',
    name: 'Itaú Unibanco Holding S/A (652)'
  },
  {
    code: '653',
    name: 'Indusval S/A (653)'
  },
  {
    code: '654',
    name: 'A.J. Renner S/A (654)'
  },
  {
    code: '655',
    name: 'Votorantim S/A (655)'
  },
  {
    code: '707',
    name: 'Daycoval S/A (707)'
  },
  {
    code: '712',
    name: 'Banco Ourinvest S/A (712)'
  },
  {
    code: '719',
    name: 'Banif Primus S/A (719)'
  },
  {
    code: '735',
    name: 'Banco Neon S.A. (735)'
  },
  {
    code: '739',
    name: 'Banco Cetelem S.A. (739)'
  },
  {
    code: '740',
    name: 'Bcn Barclays S/A (740)'
  },
  {
    code: '741',
    name: 'Ribeirao Preto S/A (741)'
  },
  {
    code: '743',
    name: 'Banco Semear S.A. (743)'
  },
  {
    code: '745',
    name: 'Banco Citibank S/A (745)'
  },
  {
    code: '746',
    name: 'Banco Modal S/A (746)'
  },
  {
    code: '747',
    name: 'Banco Rabobank International Brasil S/A (747)'
  },
  {
    code: '748',
    name: 'Banco Cooperativo Sicredi S/A (748)'
  },
  {
    code: '751',
    name: 'Scotiabank Brasil S/A Banco Múltiplo (751)'
  },
  {
    code: '752',
    name: 'Banco BNP Paribas Brasil S/A (752)'
  },
  {
    code: '753',
    name: 'Novo Banco Continental S/A (753)'
  },
  {
    code: '754',
    name: 'Banco Sistema S.A. (754)'
  },
  {
    code: '755',
    name: 'Bank of America Merrill Lynch Banco Múltiplo S/A (755)'
  },
  {
    code: '756',
    name: 'Bancoob / Sicoob (756)'
  },
  {
    code: '757',
    name: 'Banco Keb do Brasil S/A (757)'
  },
  {
    code: '901',
    name: 'Boston S/A (901)'
  },
  {
    code: '001',
    name: 'Banco do Brasil S/A (001)'
  },
  {
    code: '003',
    name: 'Banco da Amazonia (003)'
  },
  {
    code: '004',
    name: 'Banco do Nordeste do Brasil S/A (004)'
  },
  {
    code: '014',
    name: 'Natixis Brasil S/A Banco Múltiplo (014)'
  },
  {
    code: '017',
    name: 'BNY Mellon Banco S/A. (017)'
  },
  {
    code: '018',
    name: 'Banco Tricury S/A (018)'
  },
  {
    code: '019',
    name: 'Banco Azteca do Brasil S/A (019)'
  },
  {
    code: '021',
    name: 'Banestes (021)'
  },
  {
    code: '024',
    name: 'Estado de Pernambuco S/A (024)'
  },
  {
    code: '025',
    name: 'Banco Alfa (025)'
  },
  {
    code: '029',
    name: 'Banco do Estado do Rio de Janeiro (029)'
  },
  {
    code: '033',
    name: 'Santander (033)'
  },
  {
    code: '036',
    name: 'Estado do Maranhao S/A (036)'
  },
  {
    code: '037',
    name: 'Banco Banpara (037)'
  },
  {
    code: '040',
    name: 'Banco Cargill S/A (040)'
  },
  {
    code: '041',
    name: 'Banrisul (041)'
  },
  {
    code: '047',
    name: 'Banco de Sergipe (047)'
  },
  {
    code: '060',
    name: 'Confidence Corretora de Câmbio S/A (060)'
  },
  {
    code: '062',
    name: 'Hipercard Banco Múltiplo S/A (062)'
  },
  {
    code: '063',
    name: 'Banco Bradescard S/A (063)'
  },
  {
    code: '064',
    name: 'Goldman Sachs do Brasil Banco Múltiplo S/A (064)'
  },
  {
    code: '065',
    name: 'Banco Bracce S/A (065)'
  },
  {
    code: '066',
    name: 'Banco Morgan Stanley S/A (066)'
  },
  {
    code: '069',
    name: 'BPN Brasil Banco Múltiplo S/A (069)'
  },
  {
    code: '070',
    name: 'BRB (070)'
  },
  {
    code: '074',
    name: 'Banco J Safra (074)'
  },
  {
    code: '075',
    name: 'Banco ABN AMRO S/A (075)'
  },
  {
    code: '076',
    name: 'Banco KDB do Brasil S/A (076)'
  },
  {
    code: '077',
    name: 'Banco Inter S/A (077)'
  },
  {
    code: '081',
    name: 'Bbn Banco Brasileiro de Negocios S/A (081)'
  },
  {
    code: '082',
    name: 'Banco Topazio S/A (082)'
  },
  {
    code: '083',
    name: 'Banco Da China Brasil S/A (083)'
  },
  {
    code: '085',
    name: 'Cooperativa Central de Crédito Urbano (085)'
  },
  {
    code: '087',
    name: 'CC Unicred Central SC/PR (087)'
  },
  {
    code: '088',
    name: 'Banco Randon S/A (088)'
  },
  {
    code: '090',
    name: 'CC Unicred Central SP (090)'
  },
  {
    code: '091',
    name: 'Unicred Central RS (091)'
  },
  {
    code: '094',
    name: 'Banco Petra S/A (094)'
  },
  {
    code: '095',
    name: 'Banco Confidence de Câmbio S/A (095)'
  },
  {
    code: '096',
    name: 'Banco Bm&f de Liquidacao e Custodia S/A (096)'
  },
  {
    code: '097',
    name: 'BEP (097)'
  },
  {
    code: '098',
    name: 'BERS (098)'
  },
  {
    code: '099',
    name: 'BN BCO do Nordeste do Brasil S/A (099)'
  }
]

const get = code =>
  standards.find(bank => bank.code === code) ||
  all.find(bank => bank.code === code)

export {
  get,
  all,
  standards,
  withdrawFee,
}
