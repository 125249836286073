import { bank as bankList } from '../constants'
import { decorate, observable, action } from 'mobx'
import { functions } from '../firebase'

class WithdrawStore {
  investment = null
  creatingTransaction = false
  withdraw = {}

  constructor(investment) {
    this.investment = investment
    this.withdraw = {
      balance: investment.balance.current,
      percentage: 100,
      bank: {
        fee: 0
      }
    }
  }

  get netBalance() {
    return this.withdraw.balance - this.withdraw.bank.fee
  }

  setBank = code => {
    const bank = bankList.get(code)
    this.withdraw.bank = {
      ...bank,
      fee: bank.noFee ? 0 : bankList.withdrawFee
    }
  }

  setWithdraw = percentage => {
    const balance = (this.investment.balance.current * percentage) / 100
    this.withdraw = {
      ...this.withdraw,
      balance,
      percentage,
    }
  }

  request = ({ agency, account, digit }) => {
    const { strategy } = this.investment
    const { code, name, fee } = this.withdraw.bank
    const { percentage } = this.withdraw

    this.creatingTransaction = true

    return functions.investWithdraw({
      percentage,
      strategy,
      bank: {
        code,
        name,
        agency,
        account,
        digit,
        fee,
      }
    })
      .then(res => res.data)
      .then(action(transaction => {
        this.creatingTransaction = false
        return transaction
      }))
  }
}

decorate(WithdrawStore, {
  creatingTransaction: observable,
  withdraw: observable,
  setBank: action,
  setWithdraw: action,
})

export default WithdrawStore
