import React from 'react'
import styled from 'styled-components'
import { compose } from 'recompose'
import { observer, inject } from 'mobx-react'
import { UICard, UIButton, UICardElem, UIFilter, UILoading, UILayout, UITooltip } from '../UI'
import * as Modal from '../Modal'

const Title = styled.h3`
  margin: 0;
`

const SubTitle = styled.p`
  text-align: center;
  line-height: 1.5em;
  height: 4.5em;
  overflow: hidden;
`

const ShowDetailsButton = styled(UIButton)`
  margin-top: 20px;
  margin-bottom: 30px;
`

const Variation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid var(--white-two);
  width: calc(100% + 40px);
  margin-top: auto;
`

const Label = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 5px;
`

const VariationValue = styled.span`
  margin-bottom: 20px;
  font-size: 1.25em;
  font-weight: bold;
  margin-top: 0;
`

const StrategyCard = ({ modalStore, activeCampaignStore, strategy }) => {
  const { details, performance, fetchingPerformance } = strategy.store
  const lauchDate = UIFilter.DateTime({ value: details.startedIn.toDate(), format: 'MMMM [de] YYYY' }).toLocaleLowerCase()

  const showDetailsModal = () =>
    modalStore.doOpen(
      <Modal.StrategyCoins
        coins={details.coins}
        title={details.title}
        description={details.description}>
      </Modal.StrategyCoins>
    )

  const showInvestModal = () => {
    modalStore.doOpen(<Modal.Invest strategyId={strategy.id} />)
    activeCampaignStore.trackEvent('QUERO_COMPRAR', strategy.id)
  }

  return (
    <UICard>
      <Title>{details.title}</Title>
      <SubTitle>{details.resume}</SubTitle>
      <UICardElem.CoinList coins={details.coins} />
      <ShowDetailsButton text centered primary onClick={showDetailsModal}>Ver detalhes</ShowDetailsButton>
      <Variation>
        <Label>Últimas 24 horas</Label>
        <UILayout.Variation value={performance.variation24h} as={VariationValue}>
          <UILoading.Inline loading={fetchingPerformance} message="calculando...">
            <UIFilter.Percent value={performance.variation24h} />
          </UILoading.Inline>
        </UILayout.Variation>
      </Variation>
      <Variation>
        <Label>
          Performance desde o início
          <UITooltip.Help>
            Performance da estratégia desde o lançamento em {lauchDate}
          </UITooltip.Help>
        </Label>
        <UILayout.Variation value={performance.performance} as={VariationValue}>
          <UILoading.Inline loading={fetchingPerformance} message="calculando...">
            <UIFilter.Percent value={performance.performance} />
          </UILoading.Inline>
        </UILayout.Variation>
      </Variation>
      <UIButton large primary fullWidth onClick={showInvestModal}>Quero comprar</UIButton>
    </UICard >
  )
}

export default compose(
  inject('modalStore', 'activeCampaignStore'),
  observer
)(StrategyCard)
