import React from 'react'
import styled from 'styled-components'
import { UITooltip } from '..';

const Wrapper = styled.div`
  height: 84px;
`

const Label = styled.label`
  color: var(--grey200);
  font-size: 0.75em;
  display: flex;
  align-items: center;
  height: 24px;

  ${({ checkbox }) => checkbox && `
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    height: 20px;
    padding-left: 28px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: inherit;
    margin-bottom: 30px;

    &:hover input ~ ${CheckMark} {
      background-color: var(--grey100);
    }

    ${CheckMark}:after {
      left: 4px;
      top: 1px;
      width: 4px;
      height: 8px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      &:checked ~ ${CheckMark} {
        background-color: var(--grey200);
      }

      &:checked ~ ${CheckMark}:after {
        display: block;
      }
    }
  `};
`

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--greyish-brown);
  font-weight: normal;
`

const Input = styled.input`
  flex: 1;
  border: none;
  border-bottom: 2px solid #c9c9c9;
  outline: none;
  padding: 7px 5px;
  line-height: 1.5;
  color: inherit;
  width: 100%;
`

const Addon = styled.div`
  padding: 7px 5px;
  border-bottom: 2px solid #c9c9c9;
  line-height: 1.5;
`

const ErrorMessage = styled.span.attrs(() => ({
  role: 'alert',
}))`
  font-size: 0.75em;
  color: var(--lipstick);
`

const UIFormInput = ({ label, addon, marginLeft, marginRight, ...props }) => {
  const invalid = props.invalid && props.modified;

  return (
    <Wrapper {...{ marginLeft, marginRight }}>
      <Label>{label}</Label>
      <InputGroup>
        {addon && <Addon>{addon}</Addon>}
        <Input {...props} />
      </InputGroup>
      {invalid && <ErrorMessage>{props.error}</ErrorMessage>}
    </Wrapper>
  )
}

const Checkbox = styled.input.attrs(props => ({
  type: 'checkbox'
}))`

`

const CheckMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 2px solid var(--grey200);

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`

const UIFormCheckbox = ({ label, tooltip, ...props }) => {

  return (
    <Label checkbox>{label}{tooltip && <UITooltip.Help>{tooltip}</UITooltip.Help>}
      <Checkbox {...props} />
      <CheckMark />
    </Label>
  )
}

export default UIFormInput

export {
  UIFormCheckbox
}
