import React from "react";
import styled from "styled-components";
import { compose } from "recompose";
import { observer, inject } from "mobx-react";

import UINavbarHeaderInfo from "./HeaderInfo";
import UIButtonIconOnly from "../Button/IconOnly";
import PreferencesButton from "./PreferencesButton";

// import NotificationsIcon from '../../../static/images/navbar/notifications-grey.svg'
import ExitIcon from "../../../static/images/navbar/exit-grey.svg";

const Header = styled.header`
  grid-area: header;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  background-color: var(--white);
  padding: 0 60px;

  @media print {
    display: none;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const UINavbarDesktop = ({ sessionStore, userInvestmentsStore }) => {
  const { balance, fetching } = userInvestmentsStore;

  return (
    <Header>
      <Info>
        <UINavbarHeaderInfo
          label="Saldo total"
          value={fetching ? 0 : balance.current}
          variation={fetching ? 0 : balance.performance}
          fetching={fetching}
        />
        {/* <UINavbarHeaderInfo
          tooltip="É o preço para comprar 1 Bitcoin na Investtor nesse momento."
          label="BTC/BRL - Compra"
          value={coinPriceStore.bitcoin.buy}
          fetching={!coinPriceStore.bitcoin.buy} />
        <UINavbarHeaderInfo
          tooltip="É o preço para vender 1 Bitcoin na Investtor nesse momento."
          label="BTC/BRL - Venda"
          value={coinPriceStore.bitcoin.sell}
          fetching={!coinPriceStore.bitcoin.sell} /> */}
      </Info>
      <nav>
        <PreferencesButton />

        <UIButtonIconOnly
          icon={ExitIcon}
          aria-label="Sair"
          alt="Sair"
          onClick={sessionStore.signOut}
        ></UIButtonIconOnly>
      </nav>
    </Header>
  );
};

export default compose(
  inject("sessionStore", "userInvestmentsStore"),
  observer
)(UINavbarDesktop);
