import React from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { compose } from 'recompose'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { UIModalElem as UIModal, UILayout, UIForm, UIFilter } from '../../../UI'
import { routes } from '../../../../constants'
import { functions, firebaseHelper } from '../../../../firebase'

const H3 = styled.h3`
  margin-top: 50px;
`

const FaqLink = styled.a`
  display: block;
  text-align: left;
  text-transform: uppercase;
  margin-top: 20px;
  font-size: 0.875em;
`
const ModalDepositPending = ({ modalStore, transaction, history }) => {

  const goToTransactions = () => {
    modalStore.doClose()
    functions.investInformDeposit(transaction)
    history.push(`${routes.MY_INVESTMENTS}/${transaction.strategy.id}`)
  }

  const cancelDeposit = () => {
    functions.investCancelDeposit(transaction)
    modalStore.doClose()
  }

  const confirmCancelDeposit = () =>
    modalStore.showInfo({
      title: 'Cancelar depósito?',
      message: 'Tem certeza que deseja cancelar este depósito? Essa ação não pode ser revertida.',
      button: 'Sim, quero cancelar',
      action: cancelDeposit,
      showCancel: true,
      textAlign: 'left'
    })

  const status = [
    {
      completed: true,
      title: 'Solicitado',
      message: dayjs(firebaseHelper.toDate(transaction.createdAt)).format('DD MMM YYYY [-] HH:mm')
    },
    {
      current: true,
      title: 'Aguardando transferência',
      message: 'Será investido em até 2 dias úteis após o reconhecimento do depósito'
    },
    {
      title: 'Realizado',
      message: 'Sucesso'
    }
  ]

  return (
    <React.Fragment>
      <UIModal.Title>Pronto! Sua solicitação de compra foi recebida.</UIModal.Title>

      <UILayout.Statusbar {...{status}} />

      <H3>Dados para depósito</H3>

      <UILayout.BankName bankCode="001" />
      <UIForm.LabelValue label="Agência" value="0403-0" copiable />
      <UIForm.LabelValue label="Conta corrente" value="41125-6" copiable />
      <UIForm.LabelValue label="Nome" value="Investtor Serviços Digitais LTDA" copiable />
      <UIForm.LabelValue label="CNPJ" value="28.562.273/0001-49" copiable />
      <UIForm.LabelValue label="Valor"
        value={<UIFilter.Currency value={transaction.amount} />}
        copiable
        copy={transaction.value} />
      <UIForm.LabelValue label="Tipo de conta" value="Conta corrente" />
      <UIForm.LabelValue label="Titularidade" value="Outra" />

      <FaqLink href="https://investtor.com/ajuda#deposito" target="_blank">Saiba mais sobre depósitos</FaqLink>

      <UIModal.Actions
        onCancel={{
          show: true,
          click: confirmCancelDeposit,
          message: 'Cancelar depósito'
        }}
        onConfirm={{
          show: !transaction.depositInformed,
          click: goToTransactions,
          message: 'Já transferi'
        }} />

    </React.Fragment>
  )
}

export default compose(
  withRouter,
  inject('modalStore', 'userInvestmentsStore'),
  observer
)(ModalDepositPending)
