import React from 'react'
import styled from 'styled-components'

const Message = styled.p`
  ${props => {
    if (props.error) {
      return `
        color: var(--coral);
      `
    }
  }}
`

const UIFormMessage = (props) =>
  <Message {...props} />

export default UIFormMessage
