import styled from 'styled-components'
import { observer } from 'mobx-react'

const UILayoutVariation = styled.span`
  ${({ value, parentesis }) => {
    const sign = value === 0 ? '' : value > 0 ? '+ ' : '- '
    const before = (parentesis ? '(' : '') + sign
    const after = (parentesis ? ')' : '')

    return value !== undefined && `
      font-size: 14px;
      color: ${value >= 0 ? 'var(--dark-lime)' : 'var(--coral)'};
      &:before {
        content: '${before}'
      }
      &:after {
        content: '${after}'
      }
    `
  }};
`

export default observer(UILayoutVariation)
