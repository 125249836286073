import React from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { UIModalElem as UIModal, UILayout } from '../../../UI'
import { firebaseHelper } from '../../../../firebase';

const FaqLink = styled.a`
  display: block;
  text-align: left;
  text-transform: uppercase;
  margin-top: 20px;
  font-size: 0.875em;
`
const ModalWithdrawPending = ({ transaction }) => {

  const status = [
    {
      completed: true,
      title: 'Solicitado',
      message: dayjs(firebaseHelper.toDate(transaction.createdAt)).format('DD MMM YYYY [-] HH:mm')
    },
    {
      current: true,
      title: 'Processando o resgate',
      message: 'Em breve será realizado'
    },
    {
      title: 'Realizado',
      message: 'Sucesso'
    }
  ]

  return (
    <React.Fragment>
      <UIModal.Title>Pronto! Sua solicitação de resgate foi recebida.</UIModal.Title>

      <UILayout.Statusbar {...{ status }} />

      <p>Sua solicitação de resgate da estratégia <strong>{transaction.strategy.title}</strong> já está sendo processada.</p>
      
      <p>Seu resgate será creditado em sua conta bancária em até 7 dias úteis a partir do dia da sua solicitação.</p>
      <p>Só serão aceitos resgates para contas corrente do mesmo CPF cadastrado na conta da Investtor. Não aceitamos resgates enviados para contas de terceiros ou CNPJ (Pessoa Jurídica).</p>
      
      <FaqLink href="https://investtor.com/ajuda#resgate" target="_blank">Saiba mais sobre resgates</FaqLink>

    </React.Fragment>
  )
}

export default observer(ModalWithdrawPending)
