import React from 'react'
import styled from 'styled-components'
import { compose } from 'recompose'
import { inject, observer } from 'mobx-react'
import { device } from '../../constants'
import { Form, Field } from 'react-final-form'
import { UIForm, UIButton, UILoading } from '../UI'
import { composeValidators, Required, Email, MinLength } from '../UI/FormValidate'
import SignInLink from '../SignIn/Link'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 250px;

  @media ${device.laptop} {
    width: 70%;
    max-width: 500px;
  }
`

const Title = styled.h1`
  margin-top: 0;
  margin-bottom: 70px;
`

const Policies = styled.div`
  font-size: 0.75em;
`

const RegisterButton = styled(UIButton).attrs(({ invalid, loading }) => ({
  large: true,
  primary: true,
  fullWidth: true,
  disabled: invalid || loading,
  type: "submit",
}))`
  margin-top: 50px;
`

const SignIn = styled.div`
  margin-top: 60px;
`

const ErrorMessage = styled.p`
  color: var(--coral);
`

const onSubmit = userStore => values => userStore.create(values)

const SignUpForm = ({ sessionStore, userStore }) => {
  const email = new URLSearchParams(window.location.search).get('email');
  return (
    <Wrapper>
      <Title>Crie sua conta grátis</Title>
      <Form
        onSubmit={onSubmit(userStore)}
        initialValues={{
          email
        }}
        render={({ handleSubmit, pristine, invalid }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="email"
              validate={
                composeValidators(
                  Required(),
                  Email(),
                )
              }
              render={({ input, meta }) => (
                <UIForm.Input
                  {...input}
                  {...meta}
                  label="Email"
                  placeholder="joao@email.com" />
              )}
            ></Field>
            <Field
              name="password"
              validate={
                composeValidators(
                  Required(),
                  MinLength(6, 'Sua senha deve ter ao menos 6 caracteres'),
                )
              }
              render={({ input, meta }) => (
                <UIForm.Input
                  {...input}
                  {...meta}
                  type="password"
                  autocomplete="new-password"
                  label="Senha"
                  placeholder="••••••" />
              )}>
            </Field>
            <Policies>
              Ao continuar, declaro ter 18 anos de idade e aceito os <a href="https://investtor.com/termos" target="_blank" rel="noopener noreferrer">Termos de Uso</a>, a <a href="https://investtor.com/privacidade" target="_blank" rel="noopener noreferrer">Política de Privacidade</a> e o <a href="https://investtor.com/compliance" target="_blank" rel="noopener noreferrer">Manual de Compliance</a> da Investtor
            </Policies>
            <RegisterButton invalid={pristine || invalid || userStore.creating}>
              <UILoading.Button loading={userStore.creating}>
                Cadastrar
            </UILoading.Button>
            </RegisterButton>
            {!!sessionStore.authError && (
              <ErrorMessage>
                {sessionStore.authError}
              </ErrorMessage>
            )}
            <SignIn>
              Já possui uma conta? <SignInLink>Entre aqui</SignInLink>
            </SignIn>
          </form>
        )}>
      </Form>
    </Wrapper>
  )
}

export default compose(
  inject('sessionStore', 'userStore'),
  observer,
)(SignUpForm)
