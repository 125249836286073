import React from "react";
import styled from "styled-components";
import { device } from "../../../constants";
import withAuthorization from "../../withAuthorization";
import { isMobile } from "react-device-detect";

const Footer = styled.footer`
  grid-area: footer;
  background-color: var(--white);
  text-align: center;
  padding: 10px 20px;
  margin-bottom: 60px;

  @media ${device.laptop} {
    margin-bottom: 0;
    padding: 10px 60px;
  }

  @media print {
    display: none;
  }
`;

const FooterDisclaimer = styled.p`
  font-size: 0.75em;
  line-height: 1.5;

  @media ${device.laptop} {
    text-align: left;
  }
`;

const FooterLink = styled.a`
  font-size: 0.75em;
  color: var(--turquoise-blue);
  font-weight: bold;
  padding: 10px;
`;

const FooterEmailLink = styled.a`
  color: var(--turquoise-blue);
  font-weight: bold;
`;

const FooterCopyright = styled.p`
  font-size: 0.75em;
`;

const UIFooter = () =>
  !isMobile && (
    <Footer>
      <div>
        <FooterDisclaimer>
          Para saber mais sobre os valores de investimento, transferências,
          custódia e saque de criptomoedas, confira nossa{" "}
          <a
            href="https://www.investtor.com/ajuda"
            target="_blank"
            rel="noopener noreferrer"
          >
            central de ajuda
          </a>{" "}
          ou entre em contato das 9:00 às 18:00 pelo e-mail{" "}
          <FooterEmailLink href="mailto:meajuda@investtor.com" target="_blank">
            meajuda@investtor.com
          </FooterEmailLink>{" "}
          ou telefone +55 (48) 99127-5560
        </FooterDisclaimer>
        <FooterDisclaimer>
          Rentabilidade passada não é garantia de rentabilidade futura. No
          entanto, nos permite ao menos ter uma base do comportamento e da
          volatilidade dos ativos. E lembre-se: investir em criptomoedas
          significa se expor à grandes volatilidades, sem garantias de
          rendimento fixo. A rentabilidade foi calculada considerando o
          rendimento das criptomoedas desde o lançamento das estratégias em
          novembro de 2018. Seu objetivo é apenas ilustrativo. Para
          investimentos com objetivo e prazo de vencimento diferentes do que
          utilizado nesta simulação, serão feitas simulações com base no
          objetivo e prazo informado pelo usuário. Por isso, essas simulações
          não devem ser consideradas ofertas ou promessas de compra ou venda de
          criptomoedas.
        </FooterDisclaimer>
      </div>
      <div>
        <FooterLink
          href="https://investtor.com/termos"
          target="_blank"
          rel="noopener noreferrer"
        >
          Termos de Uso
        </FooterLink>
        <FooterLink
          href="https://investtor.com/privacidade"
          target="_blank"
          rel="noopener noreferrer"
        >
          Política de Privacidade
        </FooterLink>
        <FooterLink
          href="https://investtor.com/compliance"
          target="_blank"
          rel="noopener noreferrer"
        >
          Manual de Compliance
        </FooterLink>
      </div>
      <FooterCopyright>
        © {new Date().getFullYear()} Investtor - CNPJ: 28.562.273/0001-49 |
        Feito com{" "}
        <span role="img" aria-label="emoji amor">
          💜
        </span>{" "}
        em Florianópolis - SC
      </FooterCopyright>
    </Footer>
  );

export default withAuthorization()(UIFooter);
