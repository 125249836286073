import styled from 'styled-components'

const UITableHeader = styled.thead`
  tr {
    height: 30px;
  }
  th {
    text-align: left;
    font-size: 0.75em;
    text-transform: uppercase;
  }
`

export default UITableHeader
