import React from 'react'
import { compose } from 'recompose'
import { observer, inject } from 'mobx-react'
import { when } from 'mobx'
import { Form, Field } from 'react-final-form'
import { UIModalElem, UIForm } from '../UI'
import { Required, composeValidators, MinLength } from '../UI/FormValidate'
import ChangePasswordStore from '../../stores/ChangePasswordStore'

const store = new ChangePasswordStore()

const changePassword = (modalStore, { user }) => values => {
  store.changePassword(user.email, values)

  when(
    () => store.changed,
    () => {
      modalStore.showInfo({
        icon: require('../../static/images/icons/blue-success.svg'),
        info: 'Sua senha foi alterada com sucesso',
      })
    }
  )
}

const ModalChangePassword = ({ modalStore, userStore }) => {
  const showError = !!store.error

  return (
    <React.Fragment>
      <UIModalElem.Title>Trocar senha</UIModalElem.Title>
      <Form
        onSubmit={changePassword(modalStore, userStore)}
        render={({ handleSubmit, pristine, invalid }) => (
          <form onSubmit={handleSubmit}>

            <Field
              name="oldPassword"
              validate={
                composeValidators(
                  Required(),
                )
              }
              render={({ input, meta }) => (
                <UIForm.Input
                  {...input}
                  {...meta}
                  type="password"
                  autocomplete="password"
                  label="Sua senha"
                  placeholder="••••••" />
              )}>
            </Field>

            <Field
              name="newPassword"
              validate={
                composeValidators(
                  Required(),
                  MinLength(6, 'Sua senha deve ter ao menos 6 caracteres'),
                )
              }
              render={({ input, meta }) => (
                <UIForm.Input
                  {...input}
                  {...meta}
                  type="password"
                  autocomplete="new-password"
                  label="Nova senha"
                  placeholder="••••••" />
              )}>
            </Field>

            {showError && (
              <UIForm.Message error>
                {store.error}
              </UIForm.Message>
            )}

            <UIModalElem.Actions
              onConfirm={{
                show: true,
                loading: store.changing,
                message: 'Alterar',
                props: {
                  type: 'submit',
                  disabled: pristine || invalid || store.changing,
                }
              }}
              onCancel={{
                show: true,
                click: modalStore.doClose,
                message: 'Cancelar',
              }} />
          </form>
        )}>
      </Form>

    </React.Fragment>
  )
}

export default compose(
  inject('userStore', 'modalStore'),
  observer,
)(ModalChangePassword)
