import React from 'react'
import styled from 'styled-components'

import WhiteCheckIcon from '../../../static/images/icons/white-check.svg'

const Bar = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 10px;
  background-color: var(--grey100);
  border-radius: 20px;
  margin-bottom: 15px;
  margin-top: 40px;
`

const BarItem = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  position: relative;
  background-color: ${({ completed, current }) =>
    ((completed || current) && 'var(--turquoise-blue);') ||
    'inherit'
  };

  ${({ current }) => current && `
    &:not(:first-child):not(:last-child) {
      background: linear-gradient(to right, var(--turquoise-blue) 50%, transparent 0)
    }
  `};

  &:first-child {
    justify-content: flex-start;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  &:last-child {
    justify-content: flex-end;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
`

const BarItemBullet = styled.div`
  position: absolute;
  top: -5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ completed, current }) =>
    ((completed || current) && 'var(--turquoise-blue);') ||
    'inherit'
  };

  ${({ completed }) => completed && `
    display: flex;
    justify-content: center;
    align-items: center;
    top: -7px;
    width: 24px;
    height: 24px;

    img {
      width: 18px;
      height: 18px;
    }
  `};
`

const Info = styled.div`
  display: flex;
`

const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  text-align: center;

  &:first-child {
    align-items: flex-start;
    text-align: left;
  }

  &:last-child {
    align-items: flex-end;
    text-align: right;
  }
`

const InfoTitle = styled.span`
  font-weight: bold;
  line-height: 1.5;
  white-space: nowrap;
  color: ${({ completed, current }) =>
    (completed && 'var(--greyish-brown);') ||
    (current && 'var(--darkish-purple);') ||
    'var(--grey200);'
  };
`

const InfoSubTitle = styled.span`
  font-size: 0.75em;
  line-height: 1.5;
`

const UILayoutStatusbar = ({ status }) =>
  <div>
    <Bar>
      {
        status.map((item, index) =>
          <BarItem key={index} {...item}>
            <BarItemBullet {...item}>
              {
                item.completed && <img src={WhiteCheckIcon} alt="Completado" />
              }
            </BarItemBullet>
          </BarItem>
        )
      }
    </Bar>
    <Info>
      {
        status.map((item, index) =>
          <InfoItem key={index}>
            <InfoTitle {...item}>{item.title}</InfoTitle>
            <InfoSubTitle>{item.message}</InfoSubTitle>
          </InfoItem>
        )
      }
    </Info>
  </div>

export default UILayoutStatusbar
