import Email from './Email'
import FullName from './FullName'
import MinLength from './MinLength'
import Required from './Required'

const composeValidators = (...validators) => value =>
  validators.reduce(
    (error, validator) => error || validator(value),
    undefined
  )

export {
  composeValidators,
  Email,
  FullName,
  Required,
  MinLength,
}
