import React from 'react'
import { UIModal } from '..'

class UiModalButton extends React.Component {
  state = { show: false }

  doShow = () => this.setState({ show: true })
  doHide = () => this.setState({ show: false })

  render() {
    const { show } = this.state
    const { children, button: Button } = this.props

    return (
      <React.Fragment>
        <Button onClick={this.doShow}></Button>
        <UIModal show={show} onClose={this.doHide}>{children}</UIModal>
      </React.Fragment>
    )
  }
}

export default UiModalButton
