import React from "react";
import styled from "styled-components";
import { compose } from "recompose";
import { observer, inject } from "mobx-react";
import { device } from "../../constants";
import { UIGrid, UILoading } from "../UI";
import StrategyCard from "./Card";

const Title = styled.h1`
  display: none;
  margin-top: 0;

  @media ${device.laptop} {
    display: block;
    margin-bottom: 30px;
  }
`;

const sortStrategies = (a, b) => a.store.details.order - b.store.details.order;

const StrategiesPage = ({ navbarStore, strategiesStore }) => {
  navbarStore.setTitle("Estratégias");

  return (
    <UILoading.Page loading={strategiesStore.fetching}>
      <Title>Estratégias</Title>
      <UIGrid.Row>
        {strategiesStore.activeStrategies
          .slice()
          .sort(sortStrategies)
          .map((strategy, index) => (
            <UIGrid.Column key={index} sm="6" lg="4" xl="3">
              <StrategyCard strategy={strategy} />
            </UIGrid.Column>
          ))}
      </UIGrid.Row>
    </UILoading.Page>
  );
};

export default compose(
  inject("navbarStore", "strategiesStore"),
  observer
)(StrategiesPage);
