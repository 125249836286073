import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'

import BonusIcon from '../../../static/images/transactions/gift.svg'
import DepositIcon from '../../../static/images/transactions/deposit.png'
import WithdrawIcon from '../../../static/images/transactions/withdraw.png'

const getIcon = type => {
  if (type.bonus) return BonusIcon
  if (type.deposit) return DepositIcon
  return WithdrawIcon
}

const getText = type => {
  if (type.bonus) return 'Bônus/Presente'
  if (type.deposit) return 'Depósito'
  return 'Resgate'
}

const IconTransaction = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;

  &::before {
    margin-right: 10px;
    content: url(${({ type }) => getIcon(type)});
  }
`

const UIIconTransaction = ({ type, children }) =>
  <IconTransaction {...{ type }}>
    {
      children
        ? children
        : getText(type)
    }
  </IconTransaction>

export default observer(UIIconTransaction)
