import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { inject, observer } from 'mobx-react'
import PasswordForgetForm from './Form'
import { UIModalElem as UIModal } from '../../UI'
import SignUpLink from '../../SignUp/Link';

const LockIcon = styled.img.attrs(() => ({
  src: require('../../../static/images/icons/colored-lock.svg'),
  alt: 'esqueci a senha'
}))`
  margin-top: 30px;
`

const Message = styled.p`
  text-align: center;
  max-width: 420px;
  margin-top: 25px;
`

const PasswordForgetModal = ({ modalStore }) =>
  <React.Fragment>
    <UIModal.Title>Esqueci minha senha</UIModal.Title>
    <LockIcon />
    <Message>Por favor, digite seu email cadastrado. Você receberá instruções e um link para redefinir sua senha.</Message>
    <PasswordForgetForm />
    <UIModal.Footer>
      Não possui uma conta?&nbsp;<SignUpLink>Cadastre-se aqui</SignUpLink>
    </UIModal.Footer>
  </React.Fragment>

export default compose(
  withRouter,
  inject('modalStore'),
  observer
)(PasswordForgetModal)
