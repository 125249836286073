import React from 'react'
import styled from 'styled-components'
import { compose } from 'recompose'
import { inject, observer } from 'mobx-react'
import { device } from '../../../constants'
import { UICard, UIButton, UITable } from '../../UI'
import UserTransactionsStore from '../../../stores/UserTransactionsStore'
import TransactionItem from './Item';

const Card = styled(UICard)`
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 10px;

  @media ${device.laptop} {
    padding: 20px;
  }
`

const Title = styled.h3`
  align-self: flex-start;
  margin-top: 0;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 205px;
  margin-bottom: 20px;
`

const StatusButton = styled(UIButton).attrs(({ active }) => ({
  medium: true,
  width: 100,
  primary: active,
}))``

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 4px;
`

class TransactionCard extends React.Component {

  constructor(props) {
    super(props)

    const { uid } = props.sessionStore.authUser
    const { strategy } = props

    this.state = {
      showPending: true,
      store: new UserTransactionsStore(uid, strategy.id)
    }
  }

  render() {
    const { showPending, store } = this.state
    const transactions = showPending ? store.pending : store.completed

    return (
      <Card>
        <Title>Histórico de Transações</Title>
        <Buttons>
          <StatusButton active={this.state.showPending} onClick={() => this.setState({ showPending: true })}>Pendentes</StatusButton>
          <StatusButton active={!this.state.showPending} onClick={() => this.setState({ showPending: false })}>Realizadas</StatusButton>
        </Buttons>
        {
          transactions && transactions.length
            ? (
              <Table>
                <UITable.Header>
                  <tr>
                    <UITable.Column as="th" hiddenXs>{showPending ? 'Criada em' : 'Realizada em'}</UITable.Column>
                    <UITable.Column as="th">Movimentação</UITable.Column>
                    <UITable.Column as="th">Valor</UITable.Column>
                    <UITable.Column as="th"></UITable.Column>
                  </tr>
                </UITable.Header>
                <tbody>
                  {
                    transactions.map((transaction, key) => (
                      <TransactionItem {...{ transaction, key }} />
                    ))
                  }
                </tbody>
              </Table>
            ) : (
              <p>Nenhuma transação {showPending ? 'pendente' : 'realizada'}.</p>
            )
        }
      </Card>
    )
  }
}

export default compose(
  inject('sessionStore'),
  observer,
)(TransactionCard)
