import React from 'react'
import { inject, observer } from 'mobx-react'
import { compose } from 'recompose'
import { when } from 'mobx'
import { firebase, functions } from '../firebase'
import { EnviromentHelper } from '../helpers'

// import ModalRequireRegistration from './Modal/RequireRegistration'


const withAuthentication = Component => {
  class WithAuthentication extends React.Component {

    UNSAFE_componentWillMount() {
      const { sessionStore, userStore, activeCampaignStore } = this.props

      firebase.auth.onAuthStateChanged(authUser => {
        const loading = userStore.creating || sessionStore.signingIn

        when(
          () => !loading,
          () => {
            sessionStore.set(authUser || null)
            userStore.set(authUser ? authUser.uid : null)
            activeCampaignStore.setEmail(authUser && authUser.email)
            functions.userStoreSession().then(res => {
              var expiresOn = new Date()
              expiresOn.setDate(expiresOn.getDate() + 30)
              document.cookie = `invt_usr=${res.data};domain=.${EnviromentHelper.config.domain};expires=${expiresOn}`
            })
          }
        )

      //   when(
      //     () => !loading && !userStore.loading && authUser,
      //     () => {
      //       const { kycCompleted } = userStore
      //       const contactDataInformed = userContactStore.registered
      //       const userCreatedAt = parseInt(authUser.metadata.a)

      //       const isOldContact = new Date(2019, 9, 21).getTime() > userCreatedAt

      //       if (isOldContact && (!kycCompleted || !contactDataInformed)) {
      //         setTimeout(() => modalStore.doOpen(<ModalRequireRegistration />), 20000)
      //       }
      //     }
      //   )
      })
    }

    render() {
      return (
        <Component />
      )
    }
  }

  return compose(
    inject('sessionStore', 'userStore', 'activeCampaignStore', 'userContactStore', 'modalStore'),
    observer,
  )(WithAuthentication)
}

export default withAuthentication
