import React from 'react'
import styled from 'styled-components'
import { Form, Field } from 'react-final-form'
import { compose } from 'recompose'
import { inject, observer } from 'mobx-react'
import { UIForm, UIButton, UILoading } from '../../UI'
import ForgetPasswordEmailSent from './EmailSent'
import { Required, Email, composeValidators } from '../../UI/FormValidate'

const Submit = styled(UIButton)`
  margin-top: 10px;
`

const PasswordForgetForm = ({ userStore, modalStore, buttonText = 'Redefinir minha senha' }) => {
  const onSubmit = ({ email }) => {
    userStore.resetPassword(email)
      .then(() => {
        modalStore.doOpen(<ForgetPasswordEmailSent email={email} />, { centered: true, medium: true })
      }, err => {

      })
  }
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, pristine, invalid }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="email"
            validate={
              composeValidators(
                Required('Informe seu email'),
                Email(),
              )
            }
            render={({ input, meta }) => (
              <UIForm.Input {...input} {...meta} placeholder="Seu email" />
            )}>
          </Field>
          <Submit large primary centered type="submit" disabled={pristine || invalid || userStore.resetingPassword}>
            <UILoading.Button loading={userStore.resetingPassword}>
              {buttonText}
            </UILoading.Button>
          </Submit>
        </form>
      )}>
    </Form>
  )
}

export default compose(
  inject('userStore', 'modalStore'),
  observer
)(PasswordForgetForm)
