import { auth } from './firebase'

export const doCreateUserWithEmailAndPassword = (email, password) => auth.createUserWithEmailAndPassword(email, password)
export const doConfirmPasswordReset = (code, password) => auth.confirmPasswordReset(code, password)
export const doSignInWithEmailAndPassword = (email, password) => auth.signInWithEmailAndPassword(email, password)
export const doSignOut = () => auth.signOut()
export const doPasswordReset = email => auth.sendPasswordResetEmail(email)
export const doPasswordUpdate = password => auth.currentUser.updatePassword(password)
export const doSignInWithCustomToken = token => auth.signInWithCustomToken(token)
export const doVerifyPasswordResetCode = code => auth.verifyPasswordResetCode(code)
export const currentUser = () => auth.currentUser
