import React from "react";
import styled from "styled-components";
import { compose } from "recompose";
import { observer, inject } from "mobx-react";
import { device } from "../../constants";
import { UIForm, UILoading, UITable } from "../UI";
import UserRefersStore from "../../stores/UserRefersStore";
import { when } from "mobx";
import UIIconTransactionRefer from "../UI/Icon/TransactionRefer";

const Title = styled.h1`
  display: none;
  margin-top: 0;

  @media ${device.laptop} {
    display: block;
    margin-bottom: 30px;
  }
`;

const Rectangle = styled.div`
  border-radius: 4px;
  background-color: var(--white);

  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;

  padding-bottom: 50px;
  padding-top: 50px;
`;

const SubTitle = styled.h2`
  margin-top: 0;
`;

const UIIconRefer = styled.img.attrs(() => ({
  src: require("../../static/images/icons/referral.svg"),
  alt: "Indique e ganhe"
}))`
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-bottom: 30px;
`;

const RectangleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  width: 75%;
`;

const RectangleRowColumnMobile = styled.div`
  display: column;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  width: 75%;

  @media ${device.mobileL} {
    display: flex;
  }
`;

const UIIconShare = observer(
  styled.img.attrs(({ id, name }) => ({
    alt: name,
    title: name,
    src: require(`../../static/images/icons/${id}.svg`)
  }))`
    width: 24px;
    height: 24px;
    object-fit: contain;
  `
);

const IconShareBackground = styled.a`
  width: 55px;
  height: 54px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ color }) => color && `background-color: ${color};`};
  &:not(:last-child) {
    margin-right: 16px;
  }
`;

const RectangleRules = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-start;
  margin-top: 50px;
  width: 75%;
`;

const Rule = styled.span`
  line-height: 1.5;
  color: var(--greyish-brown);
`;

const Table = styled.table`
  width: 75%;
  border-collapse: separate;
  border-spacing: 0 4px;
  margin-top: 40px;
`;

const Item = styled(UITable.Row)`
  ${({ showAction }) => showAction && `cursor: pointer;`};
`;

const BorderedColumn = styled(UITable.Column).attrs(() => ({
  bordered: true
}))``;

const RectangleTotal = styled.div`
  height: 110px;
  border-radius: 4px;
  border: solid 1px var(--white-two);
  background-color: var(--white);
  width: 100%;
  &:not(:last-child) {
    margin-right: 52px;
    margin-bottom: 30px;
  }
  text-align: center;

  @media ${device.mobileL} {
    margin-bottom: 30px;
  }
`;

const ValueTotal = styled.h2`
  font-size: 28px;
  margin-top: 20px;
  margin-bottom: 4px;
`;

const DescTotal = styled.span`
  margin-top: 8px;
`;

const RectangleRowCopyLink = styled(RectangleRow)`
  display: block;
  @media ${device.laptop} {
    margin-bottom: 0px;
    width: 50%;
  }
`;

const Description = styled.span`
  text-align: center;
  margin: 0 10px 0 10px;
`;

// const DescriptionPs = styled.span`
//   margin-top: 10px;
//   font-size: 0.9em;
//   text-align: center;
//   font-weight: bolder;
// `

class ReferPage extends React.Component {
  userRefersStore = new UserRefersStore();

  constructor(props) {
    super(props);

    props.navbarStore.setTitle("Indique e ganhe");

    when(
      () => props.sessionStore.authUser,
      () => {
        props.userStore.createRefer();
        this.userRefersStore.getAll(props.sessionStore.authUser.uid);
      }
    );
  }

  // eslint-disable-next-line
  get linkRefer() {
    return window.location.protocol + "//" + window.location.hostname + "/r/";
  }

  get linkReferOnlyHostname() {
    return window.location.hostname + "/r/";
  }

  render() {
    return (
      <UILoading.Page>
        <Title>Indique e ganhe</Title>
        <Rectangle>
          <UIIconRefer />
          <SubTitle>Convide seus amigos e ganhe Bitcoin</SubTitle>
          <Description>
            A cada indicado que comprar com a Investtor, você ganha R$ 10 em
            Bitcoin.
          </Description>
          {/* <DescriptionPs>*Promoção válida para compras realizadas até 19 de Junho de 2019</DescriptionPs> */}
          <RectangleRowCopyLink>
            <UIForm.LabelValue
              value={
                this.linkReferOnlyHostname + this.props.userStore.refer.main
              }
              copiable
              copy={this.linkRefer + this.props.userStore.refer.main}
            />
          </RectangleRowCopyLink>
          <RectangleRow>
            <IconShareBackground
              color="#672683"
              href={`mailto:hello@investtor.io?&cc=&bcc=&subject=Você ganhou um presente!&body=Você ganhou R$ 5,00 para começar a investir em criptomoedas na Investtor 🤑 ${this
                .linkRefer + this.props.userStore.refer.main}`}
            >
              <UIIconShare id="email-white" name="Email" />
            </IconShareBackground>
            {/*<IconShareBackground color='#0084ff'>
                            <UIIconShare id='messenger-white' name='Facebook Messenger'/>
                        </IconShareBackground>*/}
            <IconShareBackground
              color="#25d366"
              href={`whatsapp://send?text=Você ganhou R$ 5,00 para começar a investir em criptomoedas na Investtor 🤑 ${this
                .linkRefer + this.props.userStore.refer.main}`}
            >
              <UIIconShare id="whatsapp-white" name="WhatsApp" />
            </IconShareBackground>
            <IconShareBackground
              color="#0088cc"
              href={`https://telegram.me/share/url?url=${this.linkRefer +
                this.props.userStore.refer
                  .main}&text=Voc%C3%AA%20ganhou%20R$%205,00%20para%20come%C3%A7ar%20a%20investir%20em%20criptomoedas%20na%20Investtor%20%F0%9F%A4%91`}
            >
              <UIIconShare id="telegram-white" name="Telegram" />
            </IconShareBackground>
          </RectangleRow>

          <RectangleRowColumnMobile>
            <RectangleTotal>
              <ValueTotal>R$ {this.userRefersStore.value}</ValueTotal>
              <DescTotal>Total de bônus</DescTotal>
            </RectangleTotal>
            <RectangleTotal>
              <ValueTotal>
                {!this.props.userStore.refer.clicks
                  ? 0
                  : this.props.userStore.refer.clicks}
              </ValueTotal>
              <DescTotal>Total de cliques</DescTotal>
            </RectangleTotal>
            <RectangleTotal>
              <ValueTotal>{this.userRefersStore.count}</ValueTotal>
              <DescTotal>Total de indicados</DescTotal>
            </RectangleTotal>
          </RectangleRowColumnMobile>

          {!!this.userRefersStore.refers.length ? (
            <Table>
              <UITable.Header>
                <tr>
                  <UITable.Column as="th">EMAIL</UITable.Column>
                  <UITable.Column as="th">STATUS</UITable.Column>
                </tr>
              </UITable.Header>
              <tbody>
                {this.userRefersStore.refers.map((refer, key) => (
                  <Item key={key}>
                    <BorderedColumn firstXs>{refer.email}</BorderedColumn>
                    <BorderedColumn>
                      <UIIconTransactionRefer
                        bonus={refer.bonus}
                        value={refer.value ? refer.value.BRL : 10}
                      />
                    </BorderedColumn>
                  </Item>
                ))}
              </tbody>
            </Table>
          ) : (
            ``
          )}

          <RectangleRules>
            <SubTitle>Regras gerais</SubTitle>
            <Rule>
              1. A regra é muito simples: para cada pessoa que se cadastrar por
              meio do seu link do Indique e Ganhe, você recebe R$ 10 em Bitcoin,
              que ficam pendentes até que o indicado realize uma compra na
              Investtor.
            </Rule>
            <Rule>
              2. O bônus só é liberado depois que seu amigo confirmar o cadastro
              e realizar a primeira compra de no mínimo R$ 100 na Investtor.
            </Rule>
            <Rule>
              3. O amigo indicado ganha R$ 5 em Bitcoin, creditado na estratégia
              "Bitcoin" e é válido somente para a primeira compra dele, não para
              os futuros investimentos.
            </Rule>
            <Rule>
              4. Sem limite de ganhos! O saldo é cumulativo e não existe limite
              de indicações.
            </Rule>
            <Rule>
              5. Cada indicado deve ser uma pessoa real e distinta de outros
              usuários, com nome completo, CPF, telefone e data de nascimento.
            </Rule>
          </RectangleRules>
        </Rectangle>
      </UILoading.Page>
    );
  }
}

export default compose(
  inject("navbarStore", "userStore", "sessionStore"),
  observer
)(ReferPage);
