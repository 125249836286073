import * as bank from './banks'
import * as database from './database'
import device from './devices'
import * as routes from './routes'

const states = [
  { name: "Acre", symbol: "AC" },
  { name: "Alagoas", symbol: "AL" },
  { name: "Amapá", symbol: "AP" },
  { name: "Amazonas", symbol: "AM" },
  { name: "Bahia", symbol: "BA" },
  { name: "Ceará", symbol: "CE" },
  { name: "Distrito Federal", symbol: "DF" },
  { name: "Espírito Santo", symbol: "ES" },
  { name: "Goiás", symbol: "GO" },
  { name: "Maranhão", symbol: "MA" },
  { name: "Mato Grosso", symbol: "MT" },
  { name: "Mato Grosso do Sul", symbol: "MS" },
  { name: "Minas Gerais", symbol: "MG" },
  { name: "Pará", symbol: "PA" },
  { name: "Paraíba", symbol: "PB" },
  { name: "Paraná", symbol: "PR" },
  { name: "Pernambuco", symbol: "PE" },
  { name: "Piauí", symbol: "PI" },
  { name: "Rio de Janeiro", symbol: "RJ" },
  { name: "Rio Grande do Norte", symbol: "RN" },
  { name: "Rio Grande do Sul", symbol: "RS" },
  { name: "Rondônia", symbol: "RO" },
  { name: "Roraima", symbol: "RR" },
  { name: "Santa Catarina", symbol: "SC" },
  { name: "São Paulo", symbol: "SP" },
  { name: "Sergipe", symbol: "SE" },
  { name: "Tocantins", symbol: "TO" }
]

export {
  bank,
  database,
  device,
  routes,
  states,
}
