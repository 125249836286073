import React, { Component } from 'react';

import withAuthorization from '../components/withAuthorization';
import Home from '../components/Home/Home';

class ScreensHome extends Component {

  render() {
    return <Home />;
  }
}

const userIsAuthenticated = auth => !!auth;

export default withAuthorization(userIsAuthenticated)(ScreensHome);
