import React from 'react'
import { isMobile } from 'react-device-detect'

import NavbarMobile from './Mobile'
import NavbarDesktop from './Desktop'
import { compose } from 'recompose';
import { observer, inject } from 'mobx-react';

const UINavbar = ({ sessionStore }) => {
  const hasSession = !!sessionStore.authUser && !sessionStore.signingIn

  if (!hasSession) return null

  return isMobile
    ? <NavbarMobile></NavbarMobile>
    : <NavbarDesktop></NavbarDesktop>
}

export default compose(
  inject('sessionStore'),
  observer,
)(UINavbar)
