import React from 'react';
import styled from 'styled-components';

const HomeContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const Home = () =>
  <HomeContent>
    <h1>Home</h1>
  </HomeContent>

export default Home;
