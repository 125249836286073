import { decorate, action, observable, when } from 'mobx'
import { functions, db } from '../firebase'

class UserInvestmentsStore {
  fetching = true
  fetchingCurrent = true
  investments = []
  balance = {}
  current = null

  setCurrent = (id, showLoading = true) => {
    this.fetchingCurrent = showLoading
    when(
      () => !this.fetching,
      () => {
        this.current = this.investments.find(investment => investment.id === id)
        this.fetchingCurrent = false
      }
    )
  }

  constructor(rootStore) {
    this.rootStore = rootStore

    const { sessionStore } = this.rootStore

    when(
      () => !!sessionStore.authUser,
      () => {
        db.users.investments.getAll(sessionStore.authUser.uid)
          .onSnapshot(action(snapshot => {
            const investments = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
            this.fetching = true
            functions.userInvestments(investments)
              .then(res => res.data)
              .then(action(res => {
                this.investments = res.investments
                this.balance = res.balance

                if (this.current) {
                  this.setCurrent(this.current.id, false)
                }

                this.fetching = false
              }))
              .catch(err => {
                console.log(err)
              })
          }))
      }
    )
  }
}

decorate(UserInvestmentsStore, {
  fetching: observable,
  fetchingCurrent: observable,
  investments: observable,
  balance: observable,
  current: observable,
  setCurrent: action,
})

export default UserInvestmentsStore
