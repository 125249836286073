import React from 'react'
import { compose } from 'recompose'
import { inject, observer } from 'mobx-react'
import { UIButton } from '../../UI'
import PasswordForgetModal from './Modal';

const PasswordForgetLink = ({ modalStore, children }) => {
  const modalProps = { centered: true, medium: true }
  const handleOpenModal = () => modalStore.doOpen(<PasswordForgetModal />, modalProps)
  return (
    <UIButton text primary lower onClick={handleOpenModal}>{children}</UIButton>
  )
}

export default compose(
  inject('modalStore'),
  observer
)(PasswordForgetLink)
