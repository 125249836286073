import styled from "styled-components"
import { device } from '../../../constants'

const getWidthString = span => {
  if (!span) return;

  const width = (span / 12) * 100
  return `
    width: ${width}%;

    &:not(:last-child) > * {
      margin-right: 7.5px;
    }

    &:not(:first-child) > * {
      margin-left: 7.5px;
    }
  `
}

const UIGridColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${({ xs }) => xs ? getWidthString(xs) : `width: 100%;`}

  @media ${device.tablet} {
    ${({ sm }) => sm && getWidthString(sm)}
  }

  @media ${device.laptop} {
    ${({ md }) => md && getWidthString(md)}
  }

  @media ${device.desktop} {
    ${({ lg }) => lg && getWidthString(lg)}
  }

  @media ${device.desktopL} {
    ${({ xl }) => xl && getWidthString(xl)}
  }
`

export default UIGridColumn
