import "normalize.css";
import React from "react";
import { createGlobalStyle } from "styled-components";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { observer, inject } from "mobx-react";
import { compose } from "recompose";

import {
  UIFooter,
  UIMobileMenu,
  UINavbar,
  UIModal,
  UIApp
} from "../components/UI";
import { routes } from "../constants";
import withAuthentication from "../components/withAuthentication";

import * as Screens from ".";
import UISidebarDesktop from "../components/UI/Sidebar/Desktop";

const GlobalStyles = createGlobalStyle`
  @import url("https://use.typekit.net/eix3etf.css");

  :root {
    --greyish-brown: #4a4a4a;
    --darkish-purple: #672683;
    --deep-lavender: #9653b2;
    --robin-s-egg: #60e5fb;
    --dark-lime: #8fbe00;
    --kiwi-green: #aee239;
    --lipstick: #e91e63;
    --old-pink: #c77487;
    --pig-pink: #e18f9e;
    --white-two: #eeeeee;
    --white: #ffffff;
    --turquoise-blue: #05b3c8;
    --darkish-purple-two: #541c6c;
    --maize: #ffc64d;
    --grey100: #e0e0e0;
    --brownish-grey: #757575;
    --grey200: #b2b2b2;
    --coral: #ef5350;
  }

  body {
    font-family: 'proxima-nova', Arial, Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: var(--greyish-brown);
  }

  h1 {
    font-size: 1.75em;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--greyish-brown);
  }

  h2 {
    font-size: 1.625em;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--greyish-brown);
  }

  h3 {
    font-size: 1.25em;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--greyish-brown);
  }

  p {
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--greyish-brown);
  }

  a {
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--turquoise-blue);
    text-decoration: none;
  }

  strong {
    font-weight: bold;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background-color: var(--white) !important;
    color: inherit;
  }

  .tooltip {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.3;
    text-align: center;
    background-color: var(--greyish-brown) !important;
    opacity: 1 !important;

    ::after {
      border-top-color: var(--greyish-brown) !important;
    }

    &--help {
      border-radius: 4px;
      padding: 15px;
      width: 200px;
    }
  }

  @media (max-width: 768px) {

    #hubspot-messages-iframe-container[style*="width: 108px; height: 92px;"] {
      width: 0 !important;
      height: 0 !important;
    }

    div#hubspot-messages-iframe-container div.shadow:not(.active) + iframe {
      display: none !important;
    }

  }

  .modal-portal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const ScreensRoot = ({ sessionStore }) => (
  <Router>
    <UIApp.Container authenticated={!!sessionStore.authUser}>
      <GlobalStyles />
      <UISidebarDesktop />
      <UINavbar />
      <UIMobileMenu />
      <UIModal />

      <UIApp.Main authenticated={!!sessionStore.authUser}>
        <Switch>
          <Route path={routes.ACCOUNT} component={() => <Screens.Account />} />
          {/* <Route exact path={routes.HOME} component={() => <Redirect to={routes.STRATEGIES} />} /> */}
          <Route
            exact
            path={routes.LANDING}
            component={() => <Screens.Landing />}
          />
          <Route
            path={`${routes.MY_INVESTMENTS}/:id`}
            component={() => <Screens.MyInvestmentDetails />}
          />
          <Route
            path={routes.MY_INVESTMENTS}
            component={() => <Screens.MyInvestments />}
          />
          <Route
            path={routes.PASSWORD_RESET}
            component={() => <Screens.PasswordReset />}
          />
          <Route
            path={routes.REFER_CAMPAIGN}
            component={() => <Screens.MyInvestments />}
          />
          <Route
            path={`${routes.SIGN_IN}/:token?`}
            component={() => <Screens.SignIn />}
          />
          <Route path={routes.SIGN_UP} component={() => <Screens.SignIn />} />
          <Route
            path={routes.STRATEGIES}
            component={() => <Screens.MyInvestments />}
          />
          <Route
            path={`${routes.REFER}/:refer`}
            component={() => <Screens.Refer />}
          />
          <Route
            path={routes.REPORT_IR}
            component={() => <Screens.ReportIR />}
          />
        </Switch>
      </UIApp.Main>

      <UIFooter />
    </UIApp.Container>
  </Router>
);

export default compose(
  withAuthentication,
  inject("sessionStore"),
  observer
)(ScreensRoot);
