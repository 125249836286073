import React from 'react'
import { observer } from 'mobx-react'
import styled from 'styled-components'

import { UIButtons as UIButton } from '..'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--grey100);

  ${({ asInput }) => asInput && `
    border-bottom: 2px solid var(--grey100);
  `}
`

const Icon = styled.img.attrs(({ src, alt }) => ({
  src: src,
  alt: alt
}))`
  position: absolute;
`

const Label = styled.span`
  display: inline-block;
  line-height: 1.5;

  ${({ marginLeft }) => marginLeft && `margin-left: 50px;`};

  ${({ asInput }) => asInput && `
    color: var(--grey200);
    font-size: 0.75em;
    margin-bottom: 5px;
  `}
`

const Name = styled.span`
  display: inline-block;
  line-height: 1.5;
  font-weight: bold;

  ${({ marginLeft }) => marginLeft && `margin-left: 50px;`};

  ${({ asInput }) => asInput && `
    font-weight: normal;
  `}
`

const UIFormLabelValue = ({ label, value, icon, copiable, copy, asInput }) =>
  <Wrapper {...{ asInput }}>
    {icon && <Icon src={icon} alt={value} />}
    <Label {...{ asInput }} marginLeft={!!icon}>{label}</Label>
    <Name {...{ asInput }} marginLeft={!!icon}>{value}</Name>
    {copiable && (
      <UIButton.CopyToClipboard
        copy={copy || value}
        style={{ position: 'absolute', right: 0 }}>
      </UIButton.CopyToClipboard>
    )}
  </Wrapper>

export default observer(UIFormLabelValue)
