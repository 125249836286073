import styled from 'styled-components'

const Badge = styled.span`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  height: 32px;
  border-radius: 16px;
  padding: 0 14px;
  color: var(--white);
  font-size: 0.75em;
  font-weight: bold;

  ${props =>
    (props.primary && `background-color: var(--turquoise-blue);`) ||
    (props.secondary && `background-color: var(--maize);`) ||
    `background-color: var(--grey200);`
  }
`

export default Badge
