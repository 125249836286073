import { observable, action, decorate } from 'mobx'
import { auth, firebaseError } from '../firebase'

class SessionStore {
  authUser = null
  authError = false
  signingIn = false

  constructor(rootStore) {
    this.rootStore = rootStore
    this.modalStore = rootStore.modalStore
    this.userStore = rootStore.userStore
  }

  set = authUser => this.authUser = authUser
  setAuthError = error => this.authError = error
  signIn = (email, password) => {
    this.signingIn = true

    return auth.doSignInWithEmailAndPassword(email, password)
      .then(action(authUser => {
        this.set(authUser.user)
        this.rootStore.userStore.set(authUser.user.uid)
        this.signingIn = false
      }))
      .catch(action(error => {
        this.signingIn = false
        this.authError = firebaseError.getMessageByCode(error.code)
      }))
  }

  signInWithToken = token => {
    this.signingIn = true
    return auth.doSignInWithCustomToken(token)
      .then(res => res.user)
      .then(action(user => {
        this.set(user)
        this.rootStore.userStore.set(user.uid)
        this.signingIn = false
      }))
  }

  signOut = () =>
    auth.doSignOut()
      .then(action(() => {
        this.authUser = null
        this.authError = false
        this.signingIn = false
      }))
}

decorate(SessionStore, {
  authUser: observable,
  set: action,
  setAuthError: action,
  signIn: action,
  signInWithToken: action,
  signOut: action,
})

export default SessionStore
