import React from 'react'
import styled from 'styled-components'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { UIFilter } from '..';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 90px;
  color: var(--grey200);
`
const Label = styled.label`
  font-size: 0.75em;
  display: flex;
  align-items: center;
  margin-bottom: 7px;
`

const ValueWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 9px;
`

const Value = styled.span`
  font-size: 0.875em;
`

const handleStyle = ({ disabled }) => [{
  borderColor: disabled ? '#9B9B9B' : 'var(--turquoise-blue)',
  backgroundColor: disabled ? '#9B9B9B' : 'var(--turquoise-blue)',
  marginTop: '-4px',
}]

const trackStyle = [{
  backgroundColor: 'var(--turquoise-blue)',
  borderRadius: '8px',
  height: '6px',
}]

const railStyle = {
  backgroundColor: '#d8d8d8',
  height: '6px',
}

const UIFormSlider = ({ label, minValue, maxValue, onChange }) => {
  const minPercentage = Math.ceil((minValue * 100) / maxValue)
  const disabled = maxValue <= minValue

  return (
    <Wrapper>
      <Label>{label}</Label>
      <Slider {...{ onChange, trackStyle, railStyle }} handleStyle={handleStyle({ disabled })} min={minPercentage} max={100} defaultValue={100} />
      <ValueWrapper>
        <Value>
          <UIFilter.Currency value={minValue} />
        </Value>
        <Value>
          <UIFilter.Currency value={maxValue} />
        </Value>
      </ValueWrapper>
    </Wrapper>
  )
}

export default UIFormSlider
