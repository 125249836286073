import React from "react";
import styled from "styled-components";
import { observer, inject } from "mobx-react";
import { UISidebar, UIOverlay, UIButton } from "..";

import MenuIcon from "../../../static/images/navbar/menu.svg";
import { compose } from "recompose";

const Nav = styled.nav`
  grid-area: header;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 60px;
  background-color: var(--darkish-purple);
  position: sticky;
  top: 0;

  @media print {
    display: none;
  }
`;

const MenuButton = styled(UIButton)`
  padding: 16px;

  &::before {
    content: url(${MenuIcon});
  }
`;

const Title = styled.span`
  color: var(--white);
  font-weight: bold;
`;

// const Notifications = styled.a`
//   padding: 16px;
//   margin-left: auto;

//   &::before {
//     content: url(${NotificationsIcon});
//   }
// `

const UINavbarMobile = ({ navbarStore }) => (
  <React.Fragment>
    <Nav>
      <MenuButton text onClick={navbarStore.toggleSidebar}></MenuButton>
      <Title>{navbarStore.title}</Title>
      {/* <Notifications href="#" onClick={() => console.log('click')}></Notifications> */}
    </Nav>
    {navbarStore.showSidebar && (
      <React.Fragment>
        <UIOverlay onClick={navbarStore.toggleSidebar}></UIOverlay>
        <UISidebar.Mobile></UISidebar.Mobile>
      </React.Fragment>
    )}
  </React.Fragment>
);

export default compose(inject("navbarStore"), observer)(UINavbarMobile);
