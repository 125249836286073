import { action, decorate, observable, runInAction } from 'mobx'
import { auth, firebaseError } from '../firebase'

class ChangePasswordStore {
  changing = false
  changed = false
  error = false

  changePassword = (email, { oldPassword, newPassword }) => {
    this.changed = false
    this.changing = true
    this.error = false

    auth.doSignInWithEmailAndPassword(email, oldPassword)
      .then(credentials => {
        credentials.user.updatePassword(newPassword)

        runInAction(() => {
          this.changing = false
          this.changed = true
        })
      })
      .catch(err =>
        runInAction(() => {
          this.error = firebaseError.getMessageByCode(err.code)
          this.changing = false
        })
      )
  }
}

decorate(ChangePasswordStore, {
  changed: observable,
  changing: observable,
  error: observable,
  changePassword: action
})

export default ChangePasswordStore
