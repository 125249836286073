import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { observer, inject } from 'mobx-react'
import SignInForm from './Form'
import UIAppSignInUpPage from '../UI/App/SignInUpPage'

class SignInPage extends React.Component {
  constructor(props) {
    super(props)

    const { token } = props.match.params
    const { sessionStore } = props

    if (token) {
      sessionStore.signInWithToken(token)
    }
  }

  render() {
    return (
      <UIAppSignInUpPage form={<SignInForm />}></UIAppSignInUpPage>
    )
  }
}

export default compose(
  withRouter,
  inject('modalStore', 'sessionStore'),
  observer
)(SignInPage)
