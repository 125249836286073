import React from 'react'
import { UIModalElem, UIForm } from '../UI'
import { observer, inject } from 'mobx-react'
import { compose } from 'recompose'

const ModalUserAccount = ({ userStore: { user } }) =>
  <React.Fragment>
    <UIModalElem.Title>Dados Pessoais</UIModalElem.Title>

    <UIForm.LabelValue asInput label="Nome" value={user.name} />
    <UIForm.LabelValue asInput label="Seu CPF" value={user.cpf} />
    <UIForm.LabelValue asInput label="Data de nascimento" value={user.birthday} />

  </React.Fragment>

export default compose(
  inject('userStore'),
  observer,
)(ModalUserAccount)
