import React from 'react'
import styled from 'styled-components'
import { UIButton, UIButtons, UIModalElem } from '../../UI';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { routes } from '../../../constants';

const EmailIcon = styled.img.attrs(() => ({
  src: require('../../../static/images/icons/colored-email.svg'),
  alt: 'email enviado'
}))``

const Message = styled.p`
  text-align: center;
  max-width: 420px;
  margin-top: 25px;

  strong {
    font-weight: bold;
  }
`

const ThanksButton = styled(UIButtons.Link)`
  margin-top: 30px !important;
`

class ForgetPasswordEmailSent extends React.Component {
  resended = false

  resend = () => {
    const { email, userStore } = this.props
    userStore.resetPassword(email)
      .then(() => {
        this.setState({
          resended: true
        })
      }, err => {

      })
  }

  render() {
    const { email } = this.props
    return (
      <React.Fragment>
        <EmailIcon />
        <Message>Um email foi enviado para <strong>{email}</strong> com instruções para você redefinir sua senha.</Message>
        {
          this.resended
            ? <p>Email reenviado, verifique sua caixa de entrada</p>
            : <p>Não recebeu?<UIButton text primary lower onClick={this.resend}>Enviar novamente</UIButton></p>
        }
        <ThanksButton large primary centered to={routes.SIGN_IN}>Ok, obrigado</ThanksButton>
        <UIModalElem.Footer>
          <p>Precisa de ajuda? <a href="mailto:hellp@investtor.io">meajuda@investtor.com</a></p>
        </UIModalElem.Footer>
      </React.Fragment>
    )
  }
}

export default compose(
  inject('userStore'),
  observer,
)(ForgetPasswordEmailSent)
