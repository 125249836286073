import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { compose } from 'recompose'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { routes, device } from '../../../constants'
import withAuthorization from '../../withAuthorization'
import UISidebarAccountStatus from './AccountStatus'

import LogoInvesttor from '../../../static/images/logo-investtor-full.svg'
import UserNoPicture from '../../../static/images/sidebar/no_picture.svg'

import MyInvestmentsIcon from '../../../static/images/menu/my-investments-white.svg'
import StrategiesIcon from '../../../static/images/menu/strategies-white.svg'
import HelpIcon from '../../../static/images/menu/help-white.svg'
import RefererIcon from '../../../static/images/menu/referral-white.svg'

const Nav = styled.nav`
  display: none;
  grid-area: menu;
  width: 250px;
  background-color: var(--darkish-purple);

  @media ${device.laptop} {
    display: block;
  }
`

const Fixed = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
`

const InvesttorIcon = styled.img`
  width: 150px;
  margin-top: 30px;
  align-self: center;
`

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  color: var(--white);
`

const UserAvatar = styled.img.attrs(props => ({
  src: props.src || UserNoPicture
}))`
  width: 100px;
  height: 100px;
  background-color: var(--deep-lavender);
  border-radius: 50%;
`

const UserName = styled.span`
  font-weight: bold;
  margin-top: 20px;
`

const UserEmail = styled.span`
  margin-top: 5px;
`

const MenuItem = styled(NavLink).attrs(() => ({
  activeClassName: 'active'
}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 54px;
  text-align: left;
  font-weight: 300;
  color: var(--white);

  &::before {
    content: url(${props => props.icon});
    margin-left: 30px;
    margin-right: 17px;
  }

  &.active {
    background-color: var(--darkish-purple-two);
    font-weight: bold;
    border-right: 5px solid var(--turquoise-blue);
  }

  &:first-of-type {
    margin-top: 50px;
  }

  span{
    font-size: 12px;
    color: var(--turquoise-blue);
    margin-left: 9px;
    font-weight: bold;
  }
`

const UISidebarDesktop = ({ userStore, sessionStore }) => {
  if (isMobile) return null

  const { user } = userStore
  const { email } = sessionStore.authUser

  return (
    <Nav>
      <Fixed>
        <InvesttorIcon src={LogoInvesttor} />
        <UserDetails>
          <UserAvatar />
          <UserName>{user && user.name}</UserName>
          <UserEmail>{email}</UserEmail>
        </UserDetails>

        {(!user || !user.name) &&
          <UISidebarAccountStatus />
        }

        <MenuItem icon={MyInvestmentsIcon} to={routes.MY_INVESTMENTS}>Meus Investimentos</MenuItem>
        <MenuItem icon={HelpIcon} as="a" href="https://investtor.com/ajuda" target="_blank" rel="noopener noreferrer">Ajuda</MenuItem>
      </Fixed>
    </Nav>
  )
}

export default compose(
  withAuthorization(),
  withRouter,
  inject('userStore', 'sessionStore'),
  observer,
)(UISidebarDesktop)
