import { action, decorate, observable } from 'mobx'

class VisibilityStore {
  visible = false

  toggleVisibility = () => this.visible = !this.visible
  hide = () => this.visible = false
  show = () => this.visible = true
}

decorate(VisibilityStore, {
  visible: observable,
  hide: action,
  show: action,
  toggleVisibility: action,
})

export default VisibilityStore
