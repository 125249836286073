import React from 'react'
import UIButtonIconOnly from './IconOnly';

class UIButtonCopyToClipboard extends React.Component {
  state = {
    copied: false
  }

  doCopy = () => {
    const textArea = document.createElement('textarea')

    textArea.value = this.props.copy;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      this.setState({ copied: successful })
    } catch (err) {
      console.log('Oops, unable to copy');
    }

    document.body.removeChild(textArea);
  }

  render() {
    const { copy, ...props } = this.props
    const { copied } = this.state
    const icon = copied ? 'blue-check' : 'blue-copy'
    return (
      <UIButtonIconOnly
        {...props}
        icon={require(`../../../static/images/icons/${icon}.svg`)}
        alt="Copiar"
        onClick={this.doCopy}>
      </UIButtonIconOnly>
    )
  }
}

export default UIButtonCopyToClipboard
