import React from 'react';

import withAuthorization from '../components/withAuthorization';

import ReferPage from '../components/Refer/Page';

const ScreensRefer = () =>
    <ReferPage></ReferPage>

const userIsAuthenticated = auth => !!auth;

export default withAuthorization(userIsAuthenticated)(ScreensRefer);
