import { db, firebaseHelper } from "../firebase";
import { decorate, observable, action, autorun } from "mobx";
import StrategyStore from "./StrategyStore";

class StrategiesStore {
  fetching = true;
  allStrategies = [];
  activeStrategies = [];

  constructor(rootStore) {
    this.rootStore = rootStore;

    autorun(() => {
      db.strategies.getAll().onSnapshot(
        action(snapshot => {
          const list = firebaseHelper.docsToArray(snapshot);

          this.allStrategies = list.map(this.formatStrategy);
          this.activeStrategies = list
            .filter(strategy => !strategy.disabled)
            .map(this.formatStrategy);

          this.fetching = false;
        })
      );
    });
  }

  find = id => this.allStrategies.find(strategy => strategy.id === id);
  formatStrategy = strategy => ({
    id: strategy.id,
    store: new StrategyStore(strategy)
  });
}

decorate(StrategiesStore, {
  fetching: observable,
  allStrategies: observable,
  activeStrategies: observable
});

export default StrategiesStore;
