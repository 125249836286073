import { db } from "../../firebase";
import { USERS } from "../Collections";
import * as annualBalance from "./annualBalance";
import * as contact from "./contact";
import * as investments from "./investments";
import * as processes from "./processes";
import * as refers from "./refers";
import * as transactions from "./transactions";

const usersRef = db.collection(USERS);

export const get = uid => usersRef.doc(uid);

export { annualBalance, contact, investments, processes, transactions, refers };
