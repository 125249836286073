import React from 'react'
import styled from 'styled-components'
import { compose } from 'recompose'
import { inject, observer } from 'mobx-react'
import { Form, Field } from 'react-final-form'
import { UIModalElem as UIModal, UIGrid, UIForm, UIButtons, UIButton, UIFormValidate, UITooltip, UILoading, UIFilter } from '../UI'
import { bank as bankList } from '../../constants'
import WithdrawStore from '../../stores/WithdrawStore'
import ModalTransactionWithdrawPending from './Transaction/Withdraw/Pending'

const SubTitle = styled.span`
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 15px;
`

const BankList = styled(UIGrid.Row)`
  margin-bottom: 20px;
`

const BankButton = styled(UIButtons.IconOnly)`
  opacity: ${({ active }) => active ? 1 : 0.3};

  :first-child {
    padding-left: 0
  }

  :last-child {
    padding-right: 0
  }
`

const SubmitButton = styled(UIButton)`
  margin-top: 20px;
`

class ModalWithdraw extends React.Component {

  constructor(props) {
    super(props)

    this.withdrawStore = new WithdrawStore(props.investment)
    this.strategy = props.strategiesStore.find(props.investment.strategy.id).store.details
  }

  createTransaction = ({ bankAccount, bankAgency, bankAccountDigit }) => {
    const { modalStore } = this.props
    const bankData = {
      agency: bankAgency,
      account: bankAccount,
      digit: bankAccountDigit,
    }

    return this.withdrawStore.request(bankData)
      .then(transaction => modalStore.doOpen(<ModalTransactionWithdrawPending {...{ transaction }} />))
  }

  get minValue() {
    const { balance } = this.props.investment
    const { minValue } = this.strategy

    const minPercentageWithdraw = Math.ceil((minValue * 100) / balance.current)
    const realMinValue = (balance.current * minPercentageWithdraw) / 100

    return balance.current < minValue
      ? balance.current
      : realMinValue
  }

  render() {
    const { user } = this.props.userStore
    const { withdraw, netBalance } = this.withdrawStore
    const { balance } = this.props.investment

    return (
      <React.Fragment>
        <UIModal.Title>Resgate para conta corrente</UIModal.Title>
        <p>Indique os dados abaixo para realização do resgate. A conta de destino deve ser obrigatoriamente de mesma titularidade do CPF em seu cadastro, ok?</p>
        <SubTitle>Dados para resgate</SubTitle>
        <Form
          onSubmit={this.createTransaction}
          mutators={{
            setBank: ([bank]) => {
              this.withdrawStore.setBank(bank)
            }
          }}
          initialValues={{
            percentage: withdraw.percentage,
            bankFee: UIFilter.NumberLocal({ value: withdraw.bank.fee }),
            netBalance: UIFilter.NumberLocal({ value: netBalance }),
            userName: user.name,
            userCpf: user.cpf,
            bankName: withdraw.bank.name,
            bankCode: withdraw.bank.code,
          }}
          render={({
            form: { mutators },
            handleSubmit,
            values,
            pristine,
            invalid
          }) => (
              <form onSubmit={handleSubmit}>
                <UIGrid.Row>
                  <UIGrid.Column md="12">
                    <UIForm.Slider
                      minValue={this.minValue}
                      maxValue={balance.current}
                      onChange={this.withdrawStore.setWithdraw}
                      label={
                        <React.Fragment>
                          Selecione o valor que deseja resgatar
                        <UITooltip.Help>
                            {
                              balance.current <= this.minValue
                                ? (<React.Fragment>Seu saldo é menor ou igual ao valor mínimo de resgate, só será possível resgatar o valor total.</React.Fragment>)
                                : (<React.Fragment>Selecione qualquer valor entre <UIFilter.Currency value={this.minValue} /> e <UIFilter.Currency value={balance.current} />.</React.Fragment>)
                            }
                          </UITooltip.Help>
                        </React.Fragment>
                      }></UIForm.Slider>
                  </UIGrid.Column>
                </UIGrid.Row>
                <UIGrid.Row>
                  <Field
                    name="bankFee"
                    validate={UIFormValidate.Required()}
                    render={({ input, meta }) => (
                      <UIGrid.Column md="6">
                        <UIForm.Input
                          {...input}
                          {...meta}
                          label="Taxa bancária"
                          addon="R$"
                          readOnly />
                      </UIGrid.Column>
                    )}>
                  </Field>
                  <Field
                    name="netBalance"
                    validate={UIFormValidate.Required()}
                    render={({ input, meta }) => (
                      <UIGrid.Column md="6">
                        <UIForm.Input
                          {...input}
                          {...meta}
                          label={
                            <React.Fragment>
                              Valor estimado a receber
                              <UITooltip.Help>O valor recebido poderá variar de acordo com os valores de mercado no momento do fechamento da posição.</UITooltip.Help>
                            </React.Fragment>
                          }
                          addon="R$"
                          readOnly />
                      </UIGrid.Column>
                    )}>
                  </Field>
                </UIGrid.Row>
                <SubTitle>
                  Dados bancários
                  <UITooltip.Help>Resgates somente serão realizados para contas de mesma titularidade.</UITooltip.Help>
                </SubTitle>
                <BankList>
                  {
                    bankList.standards.map((bank, index) =>
                      <BankButton
                        key={index}
                        icon={bank.icon}
                        alt={bank.name}
                        active={values.bankName === bank.name || (bank.name === 'outro' && values.bankName === 'outro')}
                        onClick={e => {
                          e.preventDefault()
                          this.withdrawStore.setBank(bank.code)
                        }} />
                    )
                  }
                </BankList>
                <UIGrid.Row>
                  {
                    values.bankName === 'Selecione...'
                      ? (
                        <Field
                          name="bankCode"
                          validate={input => (input === 'outro' || input === 'Selecione...') ? 'message' : undefined}
                          onChange={e => mutators.setBank(e.target.value)}
                          render={({ input, meta, onChange }) => (
                            <UIGrid.Column>
                              <UIForm.Select
                                {...input}
                                {...meta}
                                {...{ onChange }}
                                label="Banco">
                                <option>Selecione...</option>
                                {
                                  bankList.all.map((bank, index) => (
                                    <option
                                      key={index}
                                      value={bank.code}>
                                      {bank.name}
                                    </option>
                                  ))
                                }
                              </UIForm.Select>
                            </UIGrid.Column>
                          )} />
                      ) : (
                        <Field
                          name="bankName"
                          validate={UIFormValidate.Required()}
                          render={({ input, meta }) => (
                            <UIGrid.Column>
                              <UIForm.Input
                                {...input}
                                {...meta}
                                label="Banco"
                                readOnly />
                            </UIGrid.Column>
                          )}>
                        </Field>
                      )
                  }
                  <Field
                    name="bankAgency"
                    validate={UIFormValidate.Required()}
                    render={({ input, meta }) => (
                      <UIGrid.Column xs="4">
                        <UIForm.Input
                          {...input}
                          {...meta}
                          label="Agência (com dígito)"
                          marginRight="10px" />
                      </UIGrid.Column>
                    )}>
                  </Field>
                  <Field
                    name="bankAccount"
                    validate={UIFormValidate.Required()}
                    render={({ input, meta }) => (
                      <UIGrid.Column xs="4">
                        <UIForm.Input
                          {...input}
                          {...meta}
                          label="Conta corrente (sem dígito)" />
                      </UIGrid.Column>
                    )}>
                  </Field>
                  <Field
                    name="bankAccountDigit"
                    validate={UIFormValidate.Required()}
                    render={({ input, meta }) => (
                      <UIGrid.Column xs="4">
                        <UIForm.Input
                          {...input}
                          {...meta}
                          label="Dígito da conta"
                          marginLeft="10px" />
                      </UIGrid.Column>
                    )}
                  ></Field>
                </UIGrid.Row>
                <SubmitButton type="submit" disabled={pristine || invalid || this.withdrawStore.creatingTransaction} large primary centered>
                  <UILoading.Button loading={this.withdrawStore.creatingTransaction}>
                    Resgatar
                  </UILoading.Button>
                </SubmitButton>
              </form>
            )}>
        </Form>
      </React.Fragment >
    )
  }
}

export default compose(
  inject('userStore', 'modalStore', 'strategiesStore'),
  observer
)(ModalWithdraw)
