import React from 'react'
import styled from 'styled-components'
import { compose } from 'recompose'
import { observer, inject } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

import UIButton from '../Button'
import { device, routes } from '../../../constants'

import WhiteAlertIcon from '../../../static/images/icons/white-alert.svg'

const Status = styled.div`
  display: flex;
  align-items: center;
  color: var(--white);
  font-weight: 300;

  @media ${device.laptop} {
    margin-top: 20px;
    text-align: center;
    justify-content: center;
  }

  &:before {
    content: url(${WhiteAlertIcon});
    margin-right: 5px;
  }
`

const Button = styled(UIButton)`
  margin-top: 10px;

  @media ${device.laptop} {
    margin: auto;
    margin-top: 20px;
    margin-bottom: -20px;
  }
`

const UISidebarAccountStatus = ({ modalStore, navbarStore: { toggleSidebar }, history }) =>
  <React.Fragment>
    <Status>
      Conta não verificada
    </Status>
    <Button {...{
      primary: true,
      large: !isMobile,
      width: 190,
      onClick: () => {
        history.push(`${routes.ACCOUNT}`)
        toggleSidebar()
      }
    }}>Verificar conta</Button>
  </React.Fragment>

export default compose(
  withRouter,
  inject('modalStore', 'navbarStore'),
  observer,
)(UISidebarAccountStatus)
