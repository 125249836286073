import React from 'react';

import withAuthorization from '../components/withAuthorization';

import StrategiesPage from '../components/Strategies/Page';

const ScreensStrategies = () =>
  <StrategiesPage></StrategiesPage>

const userIsAuthenticated = auth => !!auth;

export default withAuthorization(userIsAuthenticated)(ScreensStrategies);
