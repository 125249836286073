import React from 'react'
import { UIForm } from '..'
import { bank as Bank } from '../../../constants'

const UILayoutBankName = ({ bankCode }) => {
  const bank = Bank.get(bankCode)
  return <UIForm.LabelValue icon={bank.icon} label="Banco" value={bank.name}></UIForm.LabelValue>
}

export default UILayoutBankName
