import React, { useEffect } from "react";
import styled from "styled-components";
import { observer, inject } from "mobx-react";
import { compose } from "recompose";

import LogoInvesttor from "../../static/images/logo-investtor-full-colored.svg";
import { UIFilter } from "components/UI";

const Wrapper = styled.div`
  display: none;

  @media print {
    display: flex;
    flex-direction: column;
  }
`;

const Header = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  align-items: center;
`;

const CalendarYear = styled.div`
  padding: 20px 0;
  font-size: 1.3em;
  font-weight: bold;
  text-align: center;
  border-bottom: 1px solid var(--grey200);
`;

const PayingSourceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  border-bottom: 1px solid var(--grey200);
`;

const BlockTitle = styled.span`
  font-weight: bold;
  margin-bottom: 20px;
`;

const PayingSourceData = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    line-height: 2;
  }
`;

const BalanceReport = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`;

const BalanceTable = styled.table`
  th {
    text-align: left;
  }
`;

const PrintBlock = styled.div`
  @media print {
    display: none;
  }
`;

const getCryptoSymbols = (lastYear, previousYear) => {
  const lastYearSymbols = !!lastYear ? Object.keys(lastYear) : [];
  const previousYearSymbols = !!previousYear ? Object.keys(previousYear) : [];

  return Array.from(new Set([...lastYearSymbols, ...previousYearSymbols]));
};

const getBalanceBySymbol = (symbol, records) => {
  const record = records[symbol];

  return !record
    ? {
        symbol,
        balance: 0,
        cost: 0,
        decimals: 0
      }
    : record;
};

const PayingSource = ({ name, document, documentType }) => (
  <PayingSourceWrapper>
    <BlockTitle>
      {documentType === "CPF" ? "Beneficiário" : "Fonte pagadora"}
    </BlockTitle>
    <PayingSourceData>
      <span>Nome completo</span>
      <span>{name}</span>
    </PayingSourceData>
    <PayingSourceData>
      <span>{documentType}</span>
      <span>{document}</span>
    </PayingSourceData>
  </PayingSourceWrapper>
);

function ReportIRPage({ userStore, navbarStore }) {
  const { user } = userStore;
  const { lastYear, previousYear } = userStore.annualBalance;
  const symbolList = getCryptoSymbols(lastYear, previousYear);

  useEffect(() => {
    navbarStore.setTitle("Declaração anual");
  }, [navbarStore]);

  if (lastYear === undefined || previousYear === undefined) {
    return <span>Carregando</span>;
  }

  return (
    <>
      <PrintBlock>
        <h3>Declarações disponíveis</h3>
        {/* eslint-disable-next-line */}
        <a href="#" onClick={() => window.print()}>
          Declaração IR 2019
        </a>
      </PrintBlock>
      <Wrapper>
        <Header>
          <img src={LogoInvesttor} alt="Investtor logo" />
          <span>
            Declaração de saldo em criptoativos
            <br />
            Imposto de renda pessoa física
          </span>
        </Header>
        <CalendarYear>Ano de exercício 2019</CalendarYear>
        <PayingSource
          documentType="CNPJ"
          name="Investtor Serviços Digitais LTDA"
          document="28.562.273/0001-49"
        />
        <PayingSource documentType="CPF" name={user.name} document={user.cpf} />
        <BalanceReport>
          <BlockTitle>99 - Bens e direitos (Outros bens)</BlockTitle>

          <BalanceTable>
            <thead>
              <tr>
                <th>Criptoativo</th>
                <th>Saldo em 12/2018</th>
                <th>Custo total 12/2018</th>
                <th>Saldo em 12/2019</th>
                <th>Custo total 12/2019</th>
              </tr>
            </thead>
            <tbody>
              {symbolList.map(symbol => {
                const previousBalance = getBalanceBySymbol(
                  symbol,
                  previousYear
                );
                const lastBalance = getBalanceBySymbol(symbol, lastYear);

                return (
                  <tr key={symbol}>
                    <td>{lastBalance.name || previousBalance.name}</td>
                    <td>
                      <UIFilter.Currency
                        value={previousBalance.balance}
                        decimals={previousBalance.decimals}
                        symbol={symbol}
                      />
                    </td>
                    <td>
                      <UIFilter.Currency value={previousBalance.cost} />
                    </td>
                    <td>
                      <UIFilter.Currency
                        value={lastBalance.balance}
                        decimals={lastBalance.decimals}
                        symbol={symbol}
                      />
                    </td>
                    <td>
                      <UIFilter.Currency value={lastBalance.cost} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </BalanceTable>
        </BalanceReport>
      </Wrapper>
    </>
  );
}

export default compose(
  inject("userStore", "navbarStore"),
  observer
)(ReportIRPage);
