import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import * as helpers from '../helpers'
import { UIModalElem, UIForm, UIFilter, UITable } from '../../../UI'
import { TransactionHelper } from '../../../../helpers'

const Title = styled.h3`
  margin-top: 35px;
`

const ModalDepositRealized = ({ transaction }) => {
  const type = TransactionHelper.type(transaction)
  const coins = helpers.coinsPrice(transaction)
  const showPrice = coins.some(coin => !isNaN(coin.value.price))

  return (
    <React.Fragment>
      <UIModalElem.Title>
        {
          type.bonus
            ? 'Bônus recebido'
            : 'Depósito realizado'
        }
      </UIModalElem.Title>

      <UIForm.LabelValue label="Valor" value={
        <UIFilter.Currency value={transaction.amount} />
      } />
      <UIForm.LabelValue label="Data" value={
        <UIFilter.DateTime format="DD/MM/YYYY - HH:mm" value={transaction.closedAt.toDate()} />
      } />

      <Title>Detalhes da compra</Title>

      <UITable.Distribution {...{ coins, price: showPrice, cost: showPrice }} amount />
    </React.Fragment>
  )
}

export default observer(ModalDepositRealized)
