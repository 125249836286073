import React from 'react'
import { UICard, UITable } from '../UI'
import styled from 'styled-components'
import { device } from '../../constants'

const Card = styled(UICard)`
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 10px;

  @media ${device.laptop} {
    padding: 20px;
  }
`

const Title = styled.h3`
  margin-top: 0;
  margin-bottom: 10px;

  @media ${device.laptop} {
    align-self: flex-start;
    margin-bottom: 20px;
  }
`

const DistributionCard = ({ coins }) =>
  <Card>
    <Title>Distribuição</Title>

    <UITable.Distribution {...{ coins }} value amount allocation />
  </Card>

export default DistributionCard
