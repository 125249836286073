import { db } from "../../firebase";
import { USERS, ANNUAL_BALANCE } from "../Collections";

const annualBalanceRef = uid =>
  db.collection(`${USERS}/${uid}/${ANNUAL_BALANCE}`);

export const get = (uid, year) =>
  annualBalanceRef(uid)
    .doc(year)
    .get();
