import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { compose } from "recompose";
import { observer } from "mobx-react";

import { routes } from "../../../constants";
import withAuthorization from "../../withAuthorization";

// import ChatIcon from "../../../static/images/menu/chat-blue.svg";
import StrategiesIcon from "../../../static/images/menu/strategies-blue.svg";
import MyInvestmentsIcon from "../../../static/images/menu/my-investments-blue.svg";
import RefererIcon from "../../../static/images/menu/referral-blue.svg";

const MobileMenuNav = styled.nav`
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  z-index: 999;

  @media print {
    display: none;
  }
`;

const MenuLink = styled(NavLink).attrs(props => ({
  activeClassName: "active"
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  filter: grayscale(100%) opacity(50%);

  &::before {
    content: url(${props => props.icon});
  }

  &.active {
    border-bottom: 4px solid var(--turquoise-blue);
    filter: grayscale(0) opacity(100%);
  }
`;

// const ChatLink = styled(UIButtonIconOnly)`
//   flex: 1;
//   filter: grayscale(100%) opacity(50%);
//   padding: 0;
// `;

const UIMobileMenu = () =>
  isMobile && (
    <MobileMenuNav>
      <MenuLink
        icon={MyInvestmentsIcon}
        to={routes.MY_INVESTMENTS}
        aria-label="Meus Investimentos"
      ></MenuLink>
      {/* <ChatLink icon={ChatIcon} onClick={() => window.HubSpotConversations.widget.open()} alt="Chat Investtor" aria-label="Atendimento ao usuário"></ChatLink> */}
    </MobileMenuNav>
  );

export default compose(withAuthorization(), observer)(UIMobileMenu);
