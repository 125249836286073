import React from 'react'
import styled from 'styled-components'
import { UIModalElem, UIForm, UIButton, UILoading } from '../UI'
import { Form, Field } from 'react-final-form'
import { observer, inject } from 'mobx-react'
import { compose } from 'recompose';
import { composeValidators, Required, FullName } from '../UI/FormValidate';

const Message = styled.p`
  margin-bottom: 30px;
`

const Policies = styled.div`
  font-size: 0.75em;
`

const Button = styled(UIButton)`
  margin-top: 30px;
`

const ModalCompleteRegistration = ({ userStore, userContactStore, modalStore, onSuccess }) => {

  const onSubmit = ({ name, phone, ...values }) => {
    const data = {
      nickname: name.split(' ')[0],
      name,
      phone,
      ...values,
    }
    return userStore.completeRegistration(data)
      .then(() => {
        userContactStore.add({ phone })

        return onSuccess
          ? onSuccess()
          : modalStore.doClose()
      }
      )
  }

  return (
    <React.Fragment>
      <UIModalElem.Title>
        Só falta um passo! <span role="img" aria-label="beleza">😉</span>
      </UIModalElem.Title>

      <Message>Preencha as informações abaixo para identificarmos corretamente seus depósitos e resgates. Você só vai preencher uma vez.</Message>

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, pristine, invalid }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="name"
              validate={
                composeValidators(
                  Required(),
                  FullName(),
                )
              }
              render={({ input, meta }) => (
                <UIForm.Input
                  {...input}
                  {...meta}
                  label="Nome completo"
                  autocomplete="name"
                  placeholder="João da Silva">
                </UIForm.Input>
              )}>
            </Field>
            <Field
              name="cpf"
              validate={
                composeValidators(
                  Required(),
                )
              }
              render={({ input, meta }) => (
                <UIForm.Input
                  {...input}
                  {...meta}
                  label="CPF"
                  placeholder="999.999.999-99">
                </UIForm.Input>
              )}>
            </Field>
            <Field
              name="phone"
              validate={Required()}
              render={({ input, meta }) => (
                <UIForm.Input
                  {...input}
                  {...meta}
                  label="Celular"
                  placeholder="(99) 99999 9999">
                </UIForm.Input>
              )}>
            </Field>
            <Field
              name="birthday"
              validate={Required()}
              render={({ input, meta }) => (
                <UIForm.Input
                  {...input}
                  {...meta}
                  label="Data de nascimento"
                  placeholder="dd/mm/aaaa">
                </UIForm.Input>
              )}>
            </Field>
            <Field
              name="politicallyExposed"
              type="checkbox"
              render={({ input, meta }) => (
                <UIForm.Checkbox
                  {...input}
                  {...meta}
                  label="Pessoa politicamente exposta"
                  tooltip="Pessoa politicamente exposta é aquela que desempenha ou tenha desempenhado, nos últimos 5 (cinco) anos, cargos, empregos ou funções públicas relevantes, no Brasil ou em outros países, territórios e dependências estrangeiros, assim como seus representantes, familiares e outras pessoas de seu relacionamento próximo.">
                </UIForm.Checkbox>
              )}>
            </Field>
            <Policies>
              Ao continuar, você concorda com os <a href="https://investtor.com/termos" target="_blank" rel="noopener noreferrer">Termos de Uso</a>, com a <a href="https://investtor.com/privacidade" target="_blank" rel="noopener noreferrer">Política de Privacidade</a> e com o <a href="https://investtor.com/compliance" target="_blank" rel="noopener noreferrer">Manual de Compliance</a> do Investtor
          </Policies>
            <Button type="submit" large primary centered disabled={pristine || invalid || userStore.creating}>
              <UILoading.Button loading={userStore.creating}>
                Avançar
              </UILoading.Button>
            </Button>
            {/* {!!sessionStore.authError && (
            <ErrorMessage>
              {sessionStore.authError}
            </ErrorMessage>
          )} */}
          </form>
        )}>
      </Form>
    </React.Fragment>
  )
}

export default compose(
  inject('modalStore', 'userStore', 'userContactStore'),
  observer,
)(ModalCompleteRegistration)
